import * as React from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';
import { csvToJson } from '../utils/helpers';

export const UploadAffiliateBrandOffersDialog = connect(null, {
  showNotification
  // eslint-disable-next-line no-shadow
})(({ show, setShow, refresh, brandOffersCsv, showNotification }) => {
  const uploadBrandOffers = async (brandOffers) =>
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/brandOffers/affiliateBrands`, {
      method: 'POST',
      body: JSON.stringify({ brandOffers })
    });

  React.useEffect(async () => {
    if (show) {
      const brands = csvToJson(brandOffersCsv);
      await uploadBrandOffers(brands);
      setShow(false);
      refresh();
      showNotification('All brand offers have been successfully uploaded', 'success');
    }
  }, [show]);

  return (
    <Dialog fullWidth open={show} aria-label='Fetch Brands'>
      <DialogTitle>Upload Affiliate Brand Offers</DialogTitle>
      <DialogContent>
        <div>Please wait while we upload the brand offers</div>
        <div>
          <br />
          <LinearProgress />
        </div>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
});

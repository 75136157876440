const rolePermissions = {
  sales_admin: ['rewards', 'subsidizedRewards', 'brands', 'brandOffers', 'users'],
  sales_intern: ['brands', 'brandOffers'],
  support_intern: ['users']
};

module.exports.isAuthorized = (roles, page) => {
  if (roles.includes('super_admin')) return true;
  return roles.some((r) => rolePermissions[r].includes(page));
};

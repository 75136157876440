import * as React from 'react';
import { AutocompleteInput } from 'react-admin';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function CurrencyAutoCompleteInput(props) {
  const [options, setOptions] = React.useState([]);

  const fetchOptions = () => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/regions/currencies`)
      .then((resp) => resp.json)
      .then(async (response) => {
        setOptions(response);
      });
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);

  return <AutocompleteInput fullWidth {...props} choices={options} />;
}

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, SelectArrayInput, showNotification } from 'react-admin';
import { Dialog, DialogTitle, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Form } from 'react-final-form';
import { fetchJson } from '../dataProvider';
import { AFFILIATE_NETWORKS, STAGE } from '../utils/constants';
import { downloadCSV, jsonToCsv } from '../utils/helpers';

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginTop: '10px',
    fontSize: '15px'
  }
});

export const FetchAffiliateBrandOffersDialog = connect(null, {
  showNotification
  // eslint-disable-next-line no-shadow
})(({ show, setShow, showNotification }) => {
  const classes = useStyles();
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState('');
  const handleCloseClick = () => {
    setShow(false);
  };

  const fetchBrandOffers = async () =>
    fetchJson(
      `${
        process.env.REACT_APP_API_URL
      }${STAGE}/admin/brandOffers/affiliateBrands?network=${selectedNetworks.join(',')}`
    );

  const submit = async () => {
    if (!fetching) {
      if (!selectedNetworks.length) {
        setError('Please select a network before proceeding.');
        return;
      }
      setFetching(true);
      const brandOffers = await (await fetchBrandOffers()).json;
      if (!brandOffers.length) {
        showNotification('No brand offers found for selected network', 'warning');
      } else {
        const csv = jsonToCsv(brandOffers);
        downloadCSV(csv, 'brand_offers');
        showNotification('Affiliate network brand offers fetched successfully', 'success');
      }

      setShow(false);
      setFetching(false);
      setError('');
      setShow(false);
    }
  };

  return (
    <div>
      {!fetching ? (
        <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label='Fetch Brand Offers'>
          <DialogTitle>Select Affiliate Network</DialogTitle>
          <Form
            onSubmit={submit}
            render={(formProps) => (
              <>
                <DialogContent>
                  <SelectArrayInput
                    key='type'
                    alwaysOn
                    fullWidth
                    source='affiliateNetwork'
                    onChange={(e) => {
                      setSelectedNetworks(e.target.value);
                    }}
                    allowEmpty={false}
                    choices={AFFILIATE_NETWORKS}
                  />
                  {error && <div className={classes.error}>{error}</div>}
                </DialogContent>
                <DialogActions>
                  <Button label='Fetch' type='submit' onClick={formProps.handleSubmit} />
                  <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      ) : (
        <Dialog fullWidth open={show} aria-label='Fetch Brand Offers'>
          <DialogTitle>Fetch Affiliate Brand Offers</DialogTitle>
          <DialogContent>
            <div>
              Please wait while we fetch brand offers from Affiliate Networks (
              {selectedNetworks.join(',')}). <br />
              <br />
              Once fetched, a csv file will be downloaded. Please review the file and then upload it
              using the Upload button.
            </div>
            <div>
              <br />
              <LinearProgress />
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </div>
  );
});

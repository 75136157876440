module.exports.MARKETING_EMAIL_FLAGS = {
  NEW_REWARDS: 1,
  COMMUNITY: 2
};

module.exports.REWARDS_REDEMPTION_STATUS = {
  UNPROCESSED: 0,
  PENDING: 1,
  REDEEMED: 2,
  FAILED: 3
};

module.exports.REWARD_TYPES = {
  GIFT_CARD: 'GIFT_CARD',
  LIMITED_GIFT_CARD: 'LIMITED_GIFT_CARD',
  FAKE: 'FAKE',
  CHARITY: 'CHARITY',
  SUBSIDIZED_REWARD: 'SUBSIDIZED_REWARD'
};

module.exports.REWARD_CATEGORIES = {
  ITEMS: 'Items',
  GIFT_CARDS: 'Gift Cards',
  DIGITAL_PRIZES: 'Digital Prizes',
  CARS: 'Cars',
  CHARITY: 'Charity',
  ITEM: 'Item',
  GENERAL: 'General',
  SPORTS: 'Sports',
  GAMING: 'Gaming'
};

module.exports.EXTENSION_STATE = {
  NOT_INSTALLED: 0,
  ACTIVE: 1,
  PAUSED: 2,
  LOGGED_OUT: 3
};

module.exports.MASS_USER_ACTIONS = {
  DISABLE: 'DISABLE',
  ENABLE: 'ENABLE',
  DELETE: 'DELETE',
  MARK_FRAUD: 'MARK_FRAUD',
  UN_MARK_FRAUD: 'UN_MARK_FRAUD'
};

module.exports.APP_METRICS_TIME = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  ALL_TIME: 'ALL_TIME'
};

module.exports.APP_METRICS_TYPE = {
  USER_COUNT: 'user_count',
  DELETED_USER_COUNT: 'deleted_user_count',
  DAU_COUNT: 'daily_active_users',
  NEW_ACCOUNTS_SUBMITTING_HISTORY: 'new_accounts_submitting_history',
  NEW_ACCOUNTS_INSTALLING_EXTENSION: 'new_accounts_installing_extension',
  POINTS_EARNED: 'points_earned',
  POINTS_SPENT: 'points_spent',
  REFERRALS_COMPLETED: 'referrals_completed',
  REFERRALS_IN_PROGRESS: 'referrals_in_progress',
  URLS_VISITED: 'urls_visited',
  GIVEAWAYS_SPEND_USER_COUNT: 'giveaways_spend_user_count',
  GIVEAWAYS_ENTRIES_COUNT: 'giveaways_entries_count',
  GIVEAWAYS_EARN_USER_COUNT: 'giveaways_earn_user_count',
  GIVEAWAYS_POINTS_EARNED: 'giveaways_points_earned',
  GIVEAWAYS_POINTS_SPENT: 'giveaways_points_spent',
  GIVEAWAYS_USER_COUNT: 'giveaways_user_count',
  GIVEAWAYS_USER_LOGIN_COUNT: 'giveaways_user_login_count'
};

module.exports.REDIS_DATABASE_INDICES = {
  USER_EMAIL_DOMAINS: 9,
  TENANT_POOL: 8,
  ADMIN_SETTINGS: 7,
  HISTORY_SERVICE: 6,
  PHONE_NUMBERS: 5,
  GEO_LOCATION: 4,
  USER_POOL: 3,
  KLAZIFY: 2,
  GOOGLE_MAPS: 1
};

// duplicated from surf-web/utils/constants
module.exports.AUTH_CLIENT_ID = {
  MARKETING_WEB: 'MARKETING_WEB',
  GIVEAWAYS_WEB: 'GIVEAWAYS_WEB',
  SURF_WEB: 'SURF_WEB'
};

module.exports.GEO_IP_LOCATION_COUNTRIES = {
  CANADA: 'CANADA',
  UNITED_STATES: 'UNITED STATES'
};

module.exports.MAX_PROMISE_RETRY_ATTEMPTS = 5;
module.exports.ONE_MINUTE = 1000 * 60;
module.exports.ONE_HOUR = 1000 * 60 * 60;
module.exports.ONE_DAY = 1000 * 60 * 60 * 24;

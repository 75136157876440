import * as React from 'react';
import { Button, useRefresh } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function UrlActionDialog({
  show,
  setShow,
  url,
  btnClass,
  title,
  description,
  method,
  buttonLabel,
  refreshData
}) {
  const refreshPage = useRefresh();

  const handleCloseClick = () => {
    setShow(false);
  };

  const onClickAction = () => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/${url}`, {
      method
    }).then(() => {
      setShow(false);
      refreshData();
      refreshPage(true);
    });
  };

  return (
    <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label={title}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{ReactHtmlParser(description)}</DialogContent>
      <DialogActions>
        <Button className={btnClass} label={buttonLabel} onClick={onClickAction} />
        <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
      </DialogActions>
    </Dialog>
  );
}

UrlActionDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  url: PropTypes.string,
  btnClass: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  method: PropTypes.string,
  buttonLabel: PropTypes.string,
  refreshData: PropTypes.func
};

import * as React from 'react';
import { connect } from 'react-redux';
import { SelectInput, Button, required, useDataProvider, showNotification } from 'react-admin';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export const ApplyBonusDialog = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ show, setShow, userId, refreshPage, showNotification }) => {
    const dataProvider = useDataProvider();
    const [bonuses, setBonuses] = React.useState();
    const history = useHistory();

    React.useEffect(() => {
      dataProvider
        .getList('bonuses', { pagination: { page: 1, perPage: 100 }, sort: {} })
        .then(({ data }) => setBonuses(data.map((d) => ({ id: d.name, name: d.name }))));
    }, [dataProvider]);

    const handleCloseClick = () => {
      setShow(false);
    };

    const submit = ({ bonus }) => {
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/bonuses/apply/${bonus}`, {
        method: 'POST',
        body: JSON.stringify({ userId })
      })
        .then(() => {
          setShow(false);
          showNotification('Bonus applied successfully', 'success');
          refreshPage();
          history.push(`/users/${userId}/show/points`);
          history.go();
        })
        .catch((e) => showNotification(`${e.message}`, 'warning'));
    };

    return (
      <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label='Apply Bonus'>
        <DialogTitle>Apply Bonus</DialogTitle>

        <Form
          onSubmit={submit}
          render={(formProps) => (
            <>
              <DialogContent>
                <SelectInput
                  label='Bonus name'
                  source='bonus'
                  validate={required()}
                  choices={bonuses}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button label='Apply' type='submit' onClick={formProps.handleSubmit} />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    );
  }
);

import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  TextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  required,
  SelectInput,
  DateField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  SelectArrayInput,
  FunctionField,
  Pagination
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
// eslint-disable-next-line import/no-extraneous-dependencies
import { REWARD_TYPES } from 'surf-api/utils/enumerable';
import { CloneButton } from '../components/CloneButton';
import { RewardCategoriesAutoCompleteInput } from '../components/RewardCategoriesAutoCompleteInput';
import {
  REWARD_PROVIDER_OPTIONS,
  REWARD_BRAND_TAGS,
  REWARD_TYPES_LIST,
  FEATURED_DISPLAY_ORDER
} from '../utils/constants';
import { domainValidator, urlValidator } from '../utils/validators';
import { RegionAutoCompleteInput } from '../components/RegionAutoCompleteInput';
import { LocaleAutoCompleteInput } from '../components/LocaleAutoCompleteInput';
import { CurrencyAutoCompleteInput } from '../components/CurrencyAutoCompleteInput';

const notificationValidation = (value, allValues) => {
  if (!value && allValues.notify_users) {
    return 'Notification message is required';
  }
  return undefined;
};

const rewardFilters = [
  <TextInput key='name' source='name' label='Search' alwaysOn />,
  <SelectInput
    key='showExpired'
    alwaysOn
    allowEmpty={false}
    source='showExpired'
    choices={[
      { id: false, name: 'No' },
      { id: true, name: 'Yes' }
    ]}
  />,
  <SelectInput
    key='type'
    alwaysOn
    allowEmpty={false}
    source='type'
    choices={[{ id: null, name: 'All' }].concat(REWARD_TYPES_LIST)}
  />,
  <RegionAutoCompleteInput showAll showSelect key='region' alwaysOn source='region' />
];

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />} filters={rewardFilters}>
      <Datagrid>
        <TextField sortable={false} source='name' />
        <FunctionField addLabel label='Locale' render={(record) => record?.locale.join(', ')} />
        <TextField sortable={false} source='reward_type' />
        <TextField sortable={false} source='extension_alert_count' />
        <DateField sortable={false} source='created_at' />
        <DateField showTime sortable={false} source='active_until' />
        <ShowButton />
        <CloneButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <RewardForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <RewardForm />
    </ReactAdminEdit>
  );
}

export function RewardForm({ ...props }) {
  return (
    <SimpleForm {...props}>
      <BooleanInput fullWidth source='patronus_exclusive_reward' />
      <BooleanInput fullWidth source='featured' />
      <BooleanInput fullWidth source='featured_in_extension' />
      <FormDataConsumer>
        {({ formData }) =>
          (formData.featured || formData.featured_in_extension) && (
            <SelectInput
              label='Featured Display Order'
              fullWidth
              source='featured_display_order'
              choices={FEATURED_DISPLAY_ORDER}
            />
          )
        }
      </FormDataConsumer>
      <BooleanInput fullWidth source='flash_sale' />
      <TextInput validate={required()} fullWidth source='name' />
      <TextInput validate={required()} fullWidth source='brand_name' />
      <RegionAutoCompleteInput showAll validate={required()} source='region' />
      <FormDataConsumer>
        {({ formData }) => (
          <div>
            {formData.region && formData.region.length && (
              <LocaleAutoCompleteInput
                validate={required()}
                source='locale'
                regions={formData.region}
              />
            )}
          </div>
        )}
      </FormDataConsumer>

      <RewardCategoriesAutoCompleteInput validate={required()} source='category' />

      <SelectArrayInput validate={required()} source='tags' choices={REWARD_BRAND_TAGS} fullWidth />
      <DateTimeInput fullWidth source='active_until' />

      <TextInput validate={required()} fullWidth source='description' />

      <RichTextInput fullWidth source='redemption_details' />
      <TextInput
        required
        label='Domain Targets (comma separated list)'
        fullWidth
        source='domain_targets'
        validate={domainValidator}
      />
      <TextInput label='Brand Website' fullWidth source='brand_website' validate={urlValidator} />
      <TextInput
        fullWidth
        source='brand_color'
        placeholder='#e9e9e9'
        helperText='Should be valid web color like #000000 or rgb(0,0,0) or rgba(0,0,0,1)'
      />
      <TextInput label='Brand Disclaimer' fullWidth source='brand_terms' />
      <TextInput label='Brand terms' fullWidth source='reward_terms' />

      <ImageInput validate={required()} multiple source='images' label='Images' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>

      <TextInput fullWidth source='eligibility' />

      <SelectInput
        source='reward_type'
        validate={required()}
        choices={REWARD_TYPES_LIST}
        fullWidth
      />

      <FormDataConsumer>
        {({ formData }) => (
          <>
            {[REWARD_TYPES.GIFT_CARD, REWARD_TYPES.LIMITED_GIFT_CARD].includes(
              formData.reward_type
            ) && (
              <SelectInput
                fullWidth
                label='Reward Provider'
                source='provider'
                validate={required()}
                choices={REWARD_PROVIDER_OPTIONS}
              />
            )}
            {formData.reward_type === REWARD_TYPES.LIMITED_GIFT_CARD && (
              <NumberInput
                validate={required()}
                label='Redemptions limit per week per user'
                fullWidth
                source='redemptions_per_week'
              />
            )}
            {formData.reward_type === REWARD_TYPES.SUBSIDIZED_REWARD && (
              <NumberInput
                helperText='Discount for subsidized rewards only. Add 0 if no discount'
                fullWidth
                label='Discount Percentage'
                source='discount_percentage'
              />
            )}
          </>
        )}
      </FormDataConsumer>
      <SelectInput
        fullWidth
        source='denomination_type'
        choices={[
          { id: 'fixed', name: 'Fixed' },
          { id: 'variable', name: 'Variable' }
        ]}
      />
      <ArrayInput source='denominations' validate={required()}>
        <SimpleFormIterator disableReordering inline>
          <FormDataConsumer>
            {({ getSource, formData }) => {
              const regex = /denominations\[(\d+)\]\./;
              const match = getSource('').match(regex);
              const index = parseInt(match[1]);
              return (
                <>
                  <CurrencyAutoCompleteInput
                    label='Reward Currency'
                    helperText='Override if different than reward locale currency'
                    source={getSource('currencyLocale')}
                  />
                  {formData.reward_type === REWARD_TYPES.SUBSIDIZED_REWARD && (
                    <>
                      <SelectInput
                        label='Discount Type'
                        source={getSource('type')}
                        validate={required()}
                        fullWidth
                        choices={[
                          { id: 'dollar', name: 'Dollars' },
                          { id: 'percent', name: 'Percent' }
                        ]}
                      />
                      <NumberInput
                        helperText='Minimum spend in dollars required to use reward'
                        label='Min Spend'
                        source={getSource('minSpend')}
                      />
                    </>
                  )}
                  <NumberInput
                    helperText='Cost of reward in points'
                    validate={required()}
                    label='Cost'
                    source={getSource('cost')}
                  />
                  <NumberInput
                    helperText={
                      // eslint-disable-next-line no-nested-ternary
                      formData.reward_type === REWARD_TYPES.SUBSIDIZED_REWARD
                        ? 'Value of reward in dollars/percentage'
                        : // eslint-disable-next-line no-nested-ternary
                        formData.denomination_type === 'variable'
                        ? index === 0
                          ? 'Minimum Value'
                          : 'Maximum Value'
                        : 'Value of reward in dollars'
                    }
                    validate={required()}
                    label='Value'
                    source={getSource('value')}
                  />
                  {![REWARD_TYPES.CHARITY, REWARD_TYPES.SUBSIDIZED_REWARD].includes(
                    formData.reward_type
                  ) && (
                    <TextInput
                      validate={required()}
                      label='UTID'
                      source={getSource('provider_reward_id')}
                    />
                  )}
                  {formData.reward_type === REWARD_TYPES.LIMITED_GIFT_CARD && (
                    <NumberInput
                      validate={required()}
                      label='Cards In Stock'
                      source={getSource('inventory_count')}
                    />
                  )}
                  {formData.reward_type === REWARD_TYPES.SUBSIDIZED_REWARD && (
                    <TextInput
                      label='Coupon Codes (separated by new line)'
                      multiline
                      fullWidth
                      source={getSource('coupon_codes')}
                    />
                  )}
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput fullWidth label='Active Campaign Tag Id' source='active_campaign_tag_id' />
      <h2>Notification</h2>
      <BooleanInput label='Notify All Users' source='notify_users' />
      <TextInput
        validate={notificationValidation}
        label='Notification'
        fullWidth
        source='notification_message'
        helperText='Should be no longer than 64 characters. Dynamic variables: {reward_name}'
      />
    </SimpleForm>
  );
}

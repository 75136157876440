import JWTDecode from 'jwt-decode';
import { STAGE } from './utils/constants';

const apiUrl = `${process.env.REACT_APP_API_URL}${STAGE}/admin`;

const provider = {
  login: async ({ username: email, password }) => {
    const result = await fetch(`${apiUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ email, password })
    });
    if (!result || result.status === 401) {
      throw Error('Incorrect username or password');
    }
    const session = await result.json();
    if (!session.access_token) {
      throw Error('Cannot login at this moment. Please try again');
    }
    localStorage.setItem('session', JSON.stringify(session));
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('session');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('session');
      return Promise.reject(new Error('Unauthorized'));
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    try {
      const session = JSON.parse(localStorage.getItem('session'));
      if (!session || !session.id_token) {
        return Promise.reject(new Error('Unauthorized'));
      }

      const decoded = JWTDecode(session.id_token, 'secret', 'HS512');
      const response = await fetch(`${apiUrl}/refresh`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${session.refresh_token}` },
        body: JSON.stringify({ username: decoded['cognito:username'] })
      });
      const newSession = await response.json();
      localStorage.setItem('session', JSON.stringify(newSession));
      return Promise.resolve();
    } catch (error) {
      localStorage.removeItem('session');
      return Promise.reject(new Error('Unauthorized'));
    }
  },
  getPermissions: () => {
    const session = JSON.parse(localStorage.getItem('session'));
    if (!session || !session.id_token) {
      return Promise.reject(new Error('Unauthorized'));
    }
    const decoded = JWTDecode(session.id_token, 'secret', 'HS512');
    const role = decoded['cognito:groups'];
    return role ? Promise.resolve(role) : Promise.reject(new Error('Unauthorized'));
  }
};

export default provider;

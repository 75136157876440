import * as React from 'react';
import { TextField, DateField, Button } from 'react-admin';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';
import { UrlShowField } from './UrlShowField';

const useStyles = makeStyles({
  tableContainer: {
    width: 'max-content'
  },
  showMoreBtn: {
    width: '100%'
  }
});

export function MoreAffiliateMembersTable({ LastEvaluatedKey, programId }) {
  const [pointsPaginationKey, setPointsPaginationKey] = React.useState(LastEvaluatedKey);
  const [moreMembers, setMoreMembers] = React.useState([]);
  const classes = useStyles();

  const getMoreMembers = () => {
    fetchJson(
      `${process.env.REACT_APP_API_URL}${STAGE}/admin/affiliatePrograms/${programId}/affiliateMembers`,
      {
        method: 'POST',
        body: JSON.stringify({
          paginationKey: pointsPaginationKey
        })
      }
    ).then((result) => {
      const resJson = result.json;
      setMoreMembers(moreMembers.concat(resJson.Items));
      setPointsPaginationKey(resJson.LastEvaluatedKey);
    });
  };

  return (
    <>
      {moreMembers.length > 0 && (
        <TableContainer className={classes.tableContainer}>
          <Table size='small'>
            <TableBody>
              {moreMembers.map((row) => (
                <TableRow hover key={row.created_at}>
                  <TableCell>
                    <DateField label='Created at' record={row} showTime source='created_at' />
                  </TableCell>
                  <TableCell>
                    <UrlShowField
                      record={row}
                      label='Email'
                      source='email'
                      target='_blank' // New window
                      rel='noopener noreferrer' // For security
                      resourceName='users'
                      urlSource='id'
                    />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} label='Email Verified' source='email_verified' />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} label='Phone' source='phone' />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} label='Phone' source='phone' />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} label='Fraud Flag' source='fraud_flag' />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {pointsPaginationKey && (
        <Button className={classes.showMoreBtn} onClick={() => getMoreMembers()} label='Show More'>
          <ExpandMoreIcon />
        </Button>
      )}
    </>
  );
}

MoreAffiliateMembersTable.propTypes = {
  LastEvaluatedKey: PropTypes.object,
  programId: PropTypes.string
};

import { DateField, useRecordContext } from 'react-admin';
import * as React from 'react';
import PropTypes from 'prop-types';

export function PointModifiedAtField({ source, record }) {
  let fieldRecord = record;
  if (!record) fieldRecord = useRecordContext();

  const value = record && record[source];
  const newDate = new Date(parseInt(value));
  const newTime = newDate.toISOString();
  fieldRecord.modified_at = newTime;

  return <DateField record={fieldRecord} showTime source='modified_at' />;
}

PointModifiedAtField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.any
};

import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Create as ReactAdminCreate,
  List as ReactAdminList
} from 'react-admin';
import { MASS_USER_ACTIONS } from '../utils/constants';

export function List(props) {
  return (
    <ReactAdminList {...props} exporter={false} perPage={25} pagination={<Pagination />}>
      <Datagrid>
        <TextField sortable={false} source='action' />
        <TextField sortable={false} source='initiated_by' />
        <FunctionField
          addLabel
          label='UUIDs processed'
          render={(record) => (
            <span>
              {record.uuid_processed}/{record.uuid_list?.length}
            </span>
          )}
        />
        <TextField sortable={false} source='status' />
        <DateField showTime sortable={false} source='created_at' />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <MassActionsForm />
    </ReactAdminCreate>
  );
}

function MassActionsForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput
        label="User ID's (separated by new line)"
        rows={20}
        multiline
        fullWidth
        required
        source='uuidList'
        resettable
      />
      <SelectInput source='action' validate={required()} choices={MASS_USER_ACTIONS} fullWidth />
    </SimpleForm>
  );
}

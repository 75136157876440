import React from 'react';
import { connect } from 'react-redux';
import {
  List as ReactAdminList,
  ExportButton,
  Button,
  TopToolbar,
  showNotification
} from 'react-admin';
import { useListContext } from 'ra-core';
import BackupIcon from '@material-ui/icons/Backup';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { APP_METRICS_TIME } from 'surf-api/utils/enumerable';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

function AppMetricsWidget({ data, time }) {
  return (
    <div style={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
      <h2>{time}</h2>
      <div>
        Accounts Created: <span style={{ fontWeight: 'bold' }}>{data?.user_count || 0}</span>
      </div>
      <div>
        Accounts Deleted:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.deleted_user_count || 0}</span>
      </div>
      <div>
        Daily Active Users:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.daily_active_users || 0}</span>
      </div>
      <div>
        New Accounts Submitting History:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.new_accounts_submitting_history || 0}</span>
      </div>
      <div>
        New Accounts Installing Extension:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.new_accounts_installing_extension || 0}</span>
      </div>
      <div>
        Points Earned: <span style={{ fontWeight: 'bold' }}>{data?.points_earned || 0}</span>
      </div>
      <div>
        Points Spent: <span style={{ fontWeight: 'bold' }}>{data?.points_spent || 0}</span>
      </div>
      <div>
        Referrals Completed:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.referrals_completed || 0}</span>
      </div>
      <div>
        Referrals In Progress:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.referrals_in_progress || 0}</span>
      </div>
      <div>
        URLs Visited: <span style={{ fontWeight: 'bold' }}>{data?.urls_visited || 0}</span>
      </div>
      <div>
        Giveaways Accounts Created:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_user_count || 0}</span>
      </div>
      <div>
        Giveaways Unique Logins:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_user_login_count || 0}</span>
      </div>
      <div>
        Giveaways Entries Count:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_entries_count || 0}</span>
      </div>
      <div>
        Giveaways Spend User Count:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_spend_user_count || 0}</span>
      </div>
      <div>
        Giveaways Earn User Count:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_earn_user_count || 0}</span>
      </div>
      <div>
        Giveaways Points Earned:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_points_earned || 0}</span>
      </div>
      <div>
        Giveaways Points Spent:{' '}
        <span style={{ fontWeight: 'bold' }}>{data?.giveaways_points_spent || 0}</span>
      </div>
    </div>
  );
}

AppMetricsWidget.propTypes = {
  data: PropTypes.object,
  time: PropTypes.string
};

function ListWidget() {
  const { data, loading } = useListContext();
  if (loading) {
    return null;
  }
  return (
    <>
      <AppMetricsWidget data={data[APP_METRICS_TIME.DAILY]} time={APP_METRICS_TIME.DAILY} />
      <AppMetricsWidget data={data[APP_METRICS_TIME.WEEKLY]} time={APP_METRICS_TIME.WEEKLY} />
      <AppMetricsWidget data={data[APP_METRICS_TIME.MONTHLY]} time={APP_METRICS_TIME.MONTHLY} />
      <AppMetricsWidget data={data[APP_METRICS_TIME.ALL_TIME]} time={APP_METRICS_TIME.ALL_TIME} />
    </>
  );
}

// eslint-disable-next-line no-shadow
const AppMetricsActions = connect(null, { showNotification })(({ showNotification }) => {
  const pushMetricsToSlack = () => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/appMetrics/pushToSlack`).then(() => {
      showNotification('Metrics pushed to slack successfully', 'success');
    });
  };

  return (
    <TopToolbar>
      <Button onClick={pushMetricsToSlack} label='Push to Slack'>
        <BackupIcon />
      </Button>
      <ExportButton />
    </TopToolbar>
  );
});

export function List(props) {
  return (
    <ReactAdminList {...props} actions={<AppMetricsActions />}>
      <ListWidget />
    </ReactAdminList>
  );
}

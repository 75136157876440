import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  BooleanInput
} from 'react-admin';
import { Pagination } from '../components/Pagination';

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />}>
      <Datagrid>
        <TextField sortable={false} source='name' />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <BrandForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <BrandForm />
    </ReactAdminEdit>
  );
}

function BrandForm(props) {
  return (
    <SimpleForm {...props}>
      <BooleanInput fullWidth source='featured' />
      <TextInput required fullWidth source='name' />
    </SimpleForm>
  );
}

import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  TextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  BooleanInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  NumberInput
} from 'react-admin';
import { Pagination } from '../components/Pagination';

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />}>
      <Datagrid>
        <TextField sortable={false} source='name' />
        <TextField sortable={false} source='shortName' />
        <TextField sortable={false} source='currencyCode' />
        <TextField sortable={false} source='countries' />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <RegionForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <RegionForm />
    </ReactAdminEdit>
  );
}

export function RegionForm({ ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput validate={required()} fullWidth source='name' />
      <TextInput validate={required()} fullWidth source='shortName' />
      <TextInput
        fullWidth
        source='currencyCode'
        helperText='Get currency code from https://www.xe.com/symbols/'
      />
      <TextInput
        fullWidth
        source='currencySymbol'
        helperText='Get currency symbol from https://www.xe.com/symbols/'
      />
      <ImageInput source='images' label='Icon' accept='image/*' multiple>
        <ImageField source='src' title='title' />
      </ImageInput>
      <ArrayInput source='countries' validate={required()}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }) => (
              <>
                <TextInput
                  validate={required()}
                  fullWidth
                  label='Name'
                  source={getSource('name')}
                />
                <TextInput
                  validate={required()}
                  fullWidth
                  label='Short name'
                  source={getSource('shortName')}
                />
                <BooleanInput
                  fullWidth
                  label='Use region name'
                  source={getSource('useRegionName')}
                  helperText='Set to true if we want to show region name instead of country name on Surf'
                />
                <TextInput
                  validate={required()}
                  fullWidth
                  label='Locale'
                  source={getSource('locale')}
                  helperText='This is the lowercase locale we use for user profile e.g us, ca, gb'
                />
                <TextInput
                  validate={required()}
                  fullWidth
                  label='alpha-2 ISO'
                  source={getSource('iso.alpha2')}
                  helperText='Retrieve the alpha-2 code from https://www.iban.com/country-codes '
                />
                <TextInput
                  fullWidth
                  label='alpha-3 ISO'
                  source={getSource('iso.alpha3')}
                  helperText='Retrieve the alpha-3 code from https://www.iban.com/country-codes '
                />
                <TextInput
                  fullWidth
                  label='Excluded Cities'
                  source={getSource('excludedCities')}
                  helperText={
                    "Comma separated list of cities where Surf won't be available e.g (Quebec, Montreal)"
                  }
                />
                <ImageInput
                  source={getSource('image')}
                  label='Icon'
                  accept='image/*'
                  validate={required()}
                >
                  <ImageField source='src' title='title' />
                </ImageInput>
                <BooleanInput
                  fullWidth
                  label='Use region icon'
                  source={getSource('useRegionIcon')}
                  helperText='Set to true if we want to show region icon instead of country icon on Surf'
                />
                <BooleanInput
                  fullWidth
                  label='Is Beta'
                  source={getSource('isBeta')}
                  helperText='Set to true if we want to show beta logo for Surf for this country'
                />
                <BooleanInput
                  fullWidth
                  source={getSource('isDefault')}
                  label='Is Default'
                  helperText='Only one country can be set to default. If user has no location set then this marketplace will be shown'
                />
                <NumberInput
                  validate={required()}
                  fullWidth
                  label='Country code'
                  source={getSource('countryCode')}
                  helperText='Country code (without +) for phone number e.g 1 for ca and us'
                />
                <NumberInput
                  validate={required()}
                  fullWidth
                  label='Mobile number length'
                  source={getSource('mobileNumberLength')}
                  helperText='Length of mobile number for this country'
                />
                <TextInput fullWidth label='Currency Code' source={getSource('currencyCode')} />
                <TextInput
                  fullWidth
                  label='Currency symbol'
                  source={getSource('currencySymbol')}
                  helperText='Currency symbol e.g $, £'
                />
                <BooleanInput
                  fullWidth
                  label='Use region currency'
                  source={getSource('useRegionCurrency')}
                  helperText='Set to true if we want to show region currency instead of country currency on Surf'
                />
                <TextInput
                  validate={required()}
                  fullWidth
                  label='LocaleId String'
                  source={getSource('localeIdString')}
                  helperText='Retrieve the Short String column from https://admhelp.microfocus.com/uft/en/all/VBScript/Content/html/882ca1eb-81b6-4a73-839d-154c6440bf70.htm for this country'
                />
              </>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
}

import { fetchUtils } from 'react-admin';
import queryString from 'query-string';
import { RA_PAGINATION_RESOURCES, STAGE } from './utils/constants';

const parseFile = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

// TODO: need better way to find deeply nested rawFile properties
// and convert them to strings in place
const handleUploadedFiles = async (resource, params) => {
  if (params.data.blocks) {
    const { blocks } = params.data;
    const images = await Promise.all(
      blocks.map((block) =>
        block.image?.rawFile instanceof File
          ? parseFile(block.image.rawFile)
          : Promise.resolve(block.image)
      )
    );
    const files = await Promise.all(
      blocks.map((block) =>
        block.files?.rawFile instanceof File
          ? parseFile(block.files.rawFile)
          : Promise.resolve(block.files)
      )
    );

    return {
      ...params,
      data: {
        ...params.data,
        blocks: blocks.map((b, i) => ({
          ...b,
          image: images[i],
          files: files[i]
        }))
      }
    };
  }

  if (params.data.countries) {
    const { countries } = params.data;
    const images = await Promise.all(
      countries.map((country) =>
        country.image?.rawFile instanceof File
          ? parseFile(country.image.rawFile)
          : Promise.resolve(country.image)
      )
    );
    let newFiles = [];
    let oldFiles = [];
    if (params.data.images) {
      // eslint-disable-next-line no-param-reassign
      params.data.images = [].concat(params.data.images);
      newFiles = await Promise.all(
        params.data.images.filter((p) => p.rawFile instanceof File).map((i) => parseFile(i.rawFile))
      );

      oldFiles = params.data.images.filter((p) => !(p.rawFile instanceof File));
    }
    return {
      ...params,
      data: {
        ...params.data,
        images: params.data.images ? [...oldFiles, ...newFiles] : null,
        countries: countries.map((b, i) => ({
          ...b,
          image: images[i]
        }))
      }
    };
  }

  if (!params.data.images) return params;

  // if ImageInput doesn't have "multiple" attribute react-admin sends a json instead of array.
  // eslint-disable-next-line no-param-reassign
  params.data.images = [].concat(params.data.images);
  const newFiles = await Promise.all(
    params.data.images.filter((p) => p.rawFile instanceof File).map((i) => parseFile(i.rawFile))
  );

  const oldFiles = params.data.images.filter((p) => !(p.rawFile instanceof File));

  return {
    ...params,
    data: { ...params.data, images: [...oldFiles, ...newFiles] }
  };
};

const apiUrl = `${process.env.REACT_APP_API_URL}${STAGE}/admin`;
export const fetchJson = (url, options = {}) => {
  let { headers } = options;
  if (!headers) {
    const session = JSON.parse(localStorage.getItem('session') || '{}');
    headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${session.access_token}`
    });
  }

  return fetchUtils.fetchJson(url, { ...options, headers });
};

let cache = [];
const usersPaginationTokens = [null];
const keyMapper = ({ pk, sk }) => ({ pk, sk });

const provider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    cache = cache.slice(0, perPage * page - perPage);
    let startKey = cache.length > 0 ? JSON.stringify(keyMapper(cache[cache.length - 1])) : null;

    if (resource === 'users') {
      startKey = usersPaginationTokens[page - 1];
    }
    if (RA_PAGINATION_RESOURCES.includes(resource)) {
      startKey = (page - 1) * perPage;
    }

    const query = {
      startKey,
      perPage: JSON.stringify(perPage),
      filter: JSON.stringify(params.filter)
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return fetchJson(url)
      .then(({ headers, json }) => {
        const total = +headers.get('x-total-count') || json.length;
        if (resource === 'users') {
          usersPaginationTokens.push(headers.get('x-pagination-token'));
        }
        cache = cache.concat(json);
        return { data: json, total, headers };
      })
      .catch((err) => {
        throw new Error(err.body);
      });
  },

  getOne: (resource, params) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`)
      .then(({ json }) => ({
        data: json
      }))
      .catch((err) => {
        throw new Error(err.body);
      }),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
    return fetchJson(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    cache = cache.slice(0, perPage * page - perPage);
    const startKey = cache.length > 0 && keyMapper(cache[cache.length - 1]);
    const query = {
      startKey: JSON.stringify(startKey),
      perPage: JSON.stringify(perPage),
      filter: JSON.stringify(params.filter)
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return fetchJson(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop())
    }));
  },

  create: async (resource, params) => {
    const paramsWithFiles = await handleUploadedFiles(resource, params);
    return fetchJson(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(paramsWithFiles.data)
    })
      .then(({ json }) => ({
        data: { ...paramsWithFiles.data, id: json.id || json.sk.split('#')[1] }
      }))
      .catch((err) => {
        throw new Error(err.body);
      });
  },

  update: async (resource, params) => {
    const paramsWithFiles = await handleUploadedFiles(resource, params);
    return fetchJson(`${apiUrl}/${resource}/${paramsWithFiles.id}`, {
      method: 'PUT',
      body: JSON.stringify(paramsWithFiles.data)
    })
      .then(({ json }) => ({
        data: { ...paramsWithFiles.data, id: json.sk?.split('#')[1] }
      }))
      .catch((err) => {
        throw new Error(err.body);
      });
  },

  // TODO: should cleanup uploaded files on delete
  delete: (resource, params) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE'
    }).then(({ json }) => ({ data: json })),

  // TODO: should handle file upload in updateMany as well
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return fetchJson(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  // TODO: should cleanup uploaded files on delete
  deleteMany: async (resource, params) => {
    const response = await Promise.all(
      params.ids.map((id) =>
        fetchJson(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE'
        }).then(() => id)
      )
    );
    return { data: response };
  }
};

export default provider;

import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  RichTextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  NumberInput,
  required,
  NumberField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { Pagination } from '../components/Pagination';

const programFilters = [<TextInput key='name' source='name' label='Search' alwaysOn />];

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />} filters={programFilters}>
      <Datagrid>
        <TextField sortable={false} source='name' />
        <TextField sortable={false} source='program_url' />
        <TextField sortable={false} source='signup_bonus' />
        <TextField sortable={false} source='affiliate_earning_bonus' />
        <RichTextField sortable={false} source='program_owners' />
        <TextField sortable={false} source='program_domain' />
        <NumberField sortable={false} source='signup_count' />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <AffiliateForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <AffiliateForm />
    </ReactAdminEdit>
  );
}

function AffiliateForm({ ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput validate={required()} fullWidth source='name' />
      <NumberInput validate={required()} fullWidth source='signup_bonus' />
      <NumberInput validate={required()} fullWidth source='affiliate_earning_bonus' />
      <TextInput label='Program Domain (ex. duke.edu)' fullWidth source='program_domain' />
      <ArrayInput source='program_owners' validate={required()}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }) => (
              <>
                <TextInput
                  helperText='Full Name of Affiliate'
                  validate={required()}
                  fullWidth
                  label='Name'
                  source={getSource('name')}
                />
                <ReferenceInput
                  fullWidth
                  validate={required()}
                  source={getSource('email')}
                  label='Search Email'
                  reference='users'
                  filterToQuery={(searchText) => ({ email: searchText })}
                  enableGetChoices={({ name }) => name.length > 3}
                >
                  <AutocompleteInput optionText='email' optionValue='email' />
                </ReferenceInput>
                <TextInput
                  disabled
                  validate={required()}
                  fullWidth
                  label='Email'
                  type='email'
                  source={getSource('email')}
                />
                <TextInput
                  validate={required()}
                  fullWidth
                  label='Phone'
                  source={getSource('phone')}
                />
              </>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
}

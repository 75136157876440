import React, { useState, useEffect } from 'react';
import { TextField, DateField, NumberField, Button } from 'react-admin';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { PointModifiedAtField } from './PointModifiedAtField';
import { STAGE } from '../utils/constants';

const useStyles = makeStyles({
  showMoreBtn: {
    width: '100%'
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: '600'
    }
  }
});

export function PointsTable({ points, userId }) {
  const [pointsPaginationKey, setPointsPaginationKey] = useState(points.LastEvaluatedKey);
  const [morePoints, setMorePoints] = useState(points.Items);
  const classes = useStyles();
  useEffect(() => {
    setMorePoints(points.Items);
    setPointsPaginationKey(points.LastEvaluatedKey);
  }, [points]);

  const getMorePoints = () => {
    const page = encodeURIComponent(JSON.stringify(pointsPaginationKey));
    fetchJson(
      `${process.env.REACT_APP_API_URL}${STAGE}/admin/users/${userId}/points?paginationKey=${page}`,
      {
        method: 'GET'
      }
    ).then((result) => {
      const resJson = result.json;
      resJson.Items = resJson.Items.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
      setMorePoints(morePoints.concat(resJson.Items));
      setPointsPaginationKey(resJson.LastEvaluatedKey);
    });
  };

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Modified At</TableCell>
              <TableCell>Earned At</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='right'>Total Points</TableCell>
              <TableCell>Earn Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {morePoints && morePoints.length > 0 ? (
              morePoints.map((row) => (
                <TableRow hover key={row.created_at}>
                  <TableCell>
                    <PointModifiedAtField record={row} source='timestamp' />
                  </TableCell>
                  <TableCell>
                    <DateField record={row} showTime source='created_at' />
                  </TableCell>
                  <TableCell align='right'>
                    <NumberField record={row} source='amount' />
                  </TableCell>
                  <TableCell align='right'>
                    <NumberField record={row} source='total_points' />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} source='earn_type' />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={5}>
                  No points earned or redeemed done by user
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pointsPaginationKey && (
        <Button className={classes.showMoreBtn} onClick={() => getMorePoints()} label='Show More'>
          <ExpandMoreIcon />
        </Button>
      )}
    </>
  );
}

PointsTable.propTypes = {
  points: PropTypes.object,
  userId: PropTypes.string
};

import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  Datagrid,
  ShowButton,
  CreateButton,
  ExportButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  BooleanInput,
  ImageInput,
  ImageField,
  FunctionField,
  Button,
  TopToolbar,
  useRefresh,
  SelectInput,
  SelectArrayInput,
  required,
  FormDataConsumer,
  Pagination
} from 'react-admin';
import { Button as MUIButton, Link } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BackupIcon from '@material-ui/icons/Backup';
import { makeStyles } from '@material-ui/core/styles';
import { UploadAffiliateBrandsDialog } from '../components/UploadAffiliateBrandsDialog';
import { FetchAffiliateBrandsDialog } from '../components/FetchAffiliateBrandsDialog';
import { domainValidator, urlValidator } from '../utils/validators';
import { REWARD_BRAND_TAGS } from '../utils/constants';
import { RegionAutoCompleteInput } from '../components/RegionAutoCompleteInput';
import { LocaleAutoCompleteInput } from '../components/LocaleAutoCompleteInput';

const brandFilters = [
  <TextInput key='name' source='name' label='Search' alwaysOn />,
  <SelectInput
    key='showNew'
    alwaysOn
    allowEmpty={false}
    source='showNew'
    choices={[
      { id: false, name: 'All' },
      { id: true, name: 'Yes' }
    ]}
  />,
  <RegionAutoCompleteInput showSelect showAll key='region' alwaysOn source='region' />
];

const useStyles = makeStyles({
  muiBtn: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '13px',
    textDecoration: 'none',
    '& span.MuiButton-label svg.MuiSvgIcon-root': {
      fontSize: '20px',
      marginRight: '5px',
      marginBottom: '2px'
    }
  }
});

function BrandActions() {
  const classes = useStyles();
  const [showFetchBrandsDialog, setShowFetchBrandsDialog] = React.useState(false);
  const [showUploadBrandsDialog, setShowUploadBrandsDialog] = React.useState(false);
  const [csvFile, setCsvFile] = React.useState('');
  const refresh = useRefresh();
  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton />
      <MUIButton component='label' color='primary' className={classes.muiBtn}>
        <BackupIcon />
        <label htmlFor='uploadBrands'>
          Upload Brands
          <input
            id='uploadBrands'
            aria-label='uploadBrands'
            type='file'
            accept='.csv'
            onChange={(e) => {
              const reader = new FileReader();
              reader.addEventListener('load', (event) => {
                setCsvFile(event.target.result);
                setShowUploadBrandsDialog(true);
              });
              reader.readAsText(e.target.files[0]);
            }}
            hidden
          />
        </label>
      </MUIButton>
      <Button onClick={() => setShowFetchBrandsDialog(true)} label='Fetch Brands'>
        <CloudDownloadIcon />
      </Button>
      <FetchAffiliateBrandsDialog show={showFetchBrandsDialog} setShow={setShowFetchBrandsDialog} />
      <UploadAffiliateBrandsDialog
        brandsCsv={csvFile}
        show={showUploadBrandsDialog}
        setShow={setShowUploadBrandsDialog}
        refresh={refresh}
      />
    </TopToolbar>
  );
}

export function List(props) {
  return (
    <ReactAdminList
      {...props}
      perPage={25}
      actions={<BrandActions edit={false} />}
      filters={brandFilters}
      pagination={<Pagination />}
    >
      <Datagrid>
        <TextField sortable={false} source='name' />
        <TextField sortable={false} source='featured' />
        <FunctionField addLabel label='Locale' render={(record) => record?.locale?.join(', ')} />
        <TextField sortable={false} source='affiliate_network' />
        <TextField sortable={false} source='affiliate_link' />
        <FunctionField
          addLabel
          label='Active Offers'
          render={(record) => (
            <Link
              href={`/#/brandOffers?displayedFilters=%7B%7D&filter=%7B%22brandId%22%3A%22${record.id}%22%7D`}
              target='_blank '
            >
              {record?.offer_count}
            </Link>
          )}
        />
        <TextField sortable={false} source='extension_alert_count' />
        <TextField sortable={false} label='Times Page Visited' source='brand_page_visited_count' />
        <TextField sortable={false} label='Times Offer Copied' source='brand_offer_copied_count' />
        <CreateButton basePath='/brandOffers' label='Create Offer' />
        <ShowButton label='Show' />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <BrandForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <BrandForm />
    </ReactAdminEdit>
  );
}

function BrandForm(props) {
  return (
    <SimpleForm {...props}>
      <BooleanInput fullWidth source='featured' />
      <TextInput required fullWidth source='name' />
      <TextInput required fullWidth source='description' />
      <RegionAutoCompleteInput showAll validate={required()} source='region' />
      <FormDataConsumer>
        {({ formData }) =>
          formData.region &&
          !formData.region.includes('all') && (
            <LocaleAutoCompleteInput
              validate={required()}
              source='locale'
              regions={formData.region}
            />
          )
        }
      </FormDataConsumer>
      <ImageInput validate={required()} source='images' label='Images' accept='image/*'>
        <ImageField source='src' title='name' />
      </ImageInput>
      <TextInput
        required
        label='Affiliate Link / Website URL'
        fullWidth
        source='affiliate_link'
        validate={urlValidator}
      />
      <TextInput
        required
        label='Domain Targets (comma separated list)'
        fullWidth
        source='domain_targets'
        validate={domainValidator}
      />
      <SelectArrayInput validate={required()} source='tags' choices={REWARD_BRAND_TAGS} fullWidth />
    </SimpleForm>
  );
}

import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const StateContext = createContext();
export function ThemeStateProvider({ children }) {
  const currentTheme = localStorage.getItem('theme') || 'light';
  const [theme, setTheme] = useState(currentTheme);
  const providerValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme]);

  return <StateContext.Provider value={providerValue}>{children}</StateContext.Provider>;
}

ThemeStateProvider.propTypes = {
  children: PropTypes.object
};

export const useThemeState = () => useContext(StateContext);

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { STAGE } from '../utils/constants';
import { fetchJson } from '../dataProvider';

export function AutoCompleteInput({ label, url, replaceUrlSearchVal, onChange }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const fetchOptions = async (event, newValue) => {
    if (newValue.length > 3) {
      let resourceUrl = url;
      if (replaceUrlSearchVal) {
        resourceUrl = resourceUrl.replace('searchVal', newValue);
      }
      await fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/${resourceUrl}`)
        .then((resp) => resp.json)
        .then(async (response) => {
          setOptions(response);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      onInputChange={fetchOptions}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

AutoCompleteInput.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  replaceUrlSearchVal: PropTypes.bool,
  onChange: PropTypes.func
};

import * as React from 'react';
import { AutocompleteInput, useCreateSuggestionContext } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function RewardCategoriesAutoCompleteInput({ source, validate }) {
  const [options, setOptions] = React.useState([]);

  const fetchOptions = () => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/rewardCategories/list`)
      .then((resp) => resp.json)
      .then(async (response) => {
        setOptions(response);
      });
  };

  const createRewardCategory = async (name) =>
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/rewardCategories`, {
      method: 'POST',
      body: JSON.stringify({ name })
    }).then(() => {
      fetchOptions();
    });

  React.useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <AutocompleteInput
      fullWidth
      source={source}
      choices={options}
      validate={validate}
      create={<CreateCategory createRewardCategory={createRewardCategory} />}
    />
  );
}

RewardCategoriesAutoCompleteInput.propTypes = {
  source: PropTypes.string,
  validate: PropTypes.func
};

function CreateCategory({ createRewardCategory }) {
  const { filter, onCancel } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event) => {
    event.preventDefault();
    createRewardCategory(value);
    onCancel();
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label='New category name'
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CreateCategory.propTypes = {
  createRewardCategory: PropTypes.func
};

import * as React from 'react';
import {
  SimpleForm,
  TextField,
  DateField,
  BooleanField,
  Datagrid,
  ShowButton,
  Tab,
  Toolbar,
  SaveButton,
  Edit as ReactAdminEdit,
  Show as ReactAdminShow,
  List as ReactAdminList,
  TextInput,
  DateInput,
  BooleanInput,
  ArrayField,
  AutocompleteInput,
  NumberField,
  ReferenceInput,
  Button,
  TabbedShowLayout,
  FormDataConsumer,
  EditButton,
  TopToolbar,
  SelectInput,
  useRefresh,
  useRecordContext,
  FunctionField,
  showNotification
} from 'react-admin';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import AndroidIcon from '@material-ui/icons/Android';
import BugReportIcon from '@material-ui/icons/BugReport';
import { Button as MUIButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { EXTENSION_STATES, STAGE, GENDER_OPTIONS, ADMIN_USER_ACTIONS } from '../utils/constants';
import { ApplyBonusDialog } from '../components/ApplyBonusDialog';
import { CreateReferralDialog } from '../components/CreateReferralDialog';
import { UrlActionDialog } from '../components/UrlActionDialog';
import { Pagination } from '../components/Pagination';
import { PointsTable } from '../components/PointsTable';
import { CreateRedemptionDialog } from '../components/CreateRedemptionDialog';
import { AssignAffiliateDialog } from '../components/AssignAffiliateDialog';
import { MoreAffiliateMembersTable } from '../components/MoreAffiliateMembersTable';
import { UrlShowField } from '../components/UrlShowField';
import { DeleteUserDialog } from '../components/DeleteUserDialog';
import { fetchJson } from '../dataProvider';
import { UserRedemptionsTable } from '../components/UserRedemptionsTable';
import { SessionsTable } from '../components/SessionsTable';
import { ReferralsTable } from '../components/ReferralsTable';
import { RegionAutoCompleteInput } from '../components/RegionAutoCompleteInput';
import { LocaleAutoCompleteInput } from '../components/LocaleAutoCompleteInput';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteBtn: {
    color: 'red'
  },
  enableBtn: {
    color: 'green'
  },
  tableContainer: {
    width: 'max-content'
  },
  disabledUserDiv: {
    textAlign: 'center',
    color: 'white',
    background: 'red',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  dangerFlag: {
    flex: 'auto',
    background: 'red',
    color: 'white',
    fontSize: 'large',
    marginRight: '10px'
  },
  dangerFlagChip: {
    background: 'red',
    color: 'white',
    height: '23px',
    marginLeft: '5px'
  },
  flagsWrapper: {
    flex: 'auto'
  }
});

const userFilters = [
  <TextInput key='email' source='email' label='Search Email' alwaysOn />,
  <TextInput key='phone_number' source='phone_number' label='Search Phone' alwaysOn />,
  <TextInput key='id' source='id' label='Search userId' alwaysOn />
];

const UserActions = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ basePath, showNotification, data, edit = true }) => {
    const classes = useStyles();
    const [showApplyBonusDialog, setShowApplyBonusDialog] = React.useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [showCreateRedemptionDialog, setShowCreateRedemptionDialog] = React.useState(false);
    const [showCreateReferralDialog, setShowCreateReferralDialog] = React.useState(false);
    const [showAssignAffiliateDialog, setShowAssignAffiliateDialog] = React.useState(false);
    const [showMarkFraudDialog, setShowMarkFraudDialog] = React.useState(false);
    const [showUnMarkFraudDialog, setShowUnMarkFraudDialog] = React.useState(false);
    const [showDisableDialog, setShowDisableDialog] = React.useState(false);
    const [showEnableDialog, setShowEnableDialog] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreActionsClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMoreActionsClose = () => {
      setAnchorEl(null);
    };

    const refresh = useRefresh();

    const updateBotScore = async (sub) => {
      await fetchJson(
        `${process.env.REACT_APP_API_URL}${STAGE}/admin/users/${sub}/botScore/update`,
        {
          method: 'GET'
        }
      )
        .then(() => {
          showNotification('Bot score updated successfully', 'success');
          refresh();
        })
        .catch((err) => showNotification(`Could not update bot score. ${err}`, 'warning'));
    };

    const moreActionsMenuSelect = (e) => {
      handleMoreActionsClose();
      switch (e) {
        case ADMIN_USER_ACTIONS.ASSIGN_AFFILIATE_PROGRAM:
          return setShowAssignAffiliateDialog(true);
        case ADMIN_USER_ACTIONS.CREATE_REFERRAL:
          return setShowCreateReferralDialog(true);
        case ADMIN_USER_ACTIONS.APPLY_BONUS:
          return setShowApplyBonusDialog(true);
        case ADMIN_USER_ACTIONS.CREATE_REDEMPTION:
          return setShowCreateRedemptionDialog(true);
        case ADMIN_USER_ACTIONS.MARK_FRAUD:
          return setShowMarkFraudDialog(true);
        case ADMIN_USER_ACTIONS.UN_MARK_FRAUD:
          return setShowUnMarkFraudDialog(true);
        default:
          return null;
      }
    };

    return (
      <TopToolbar>
        {data && (
          <>
            {(data.fraud_flag || !data.enabled) && (
              <div className={classes.flagsWrapper}>
                {data.fraud_flag && (
                  <Chip
                    className={classes.dangerFlag}
                    color='secondary'
                    label='FRAUD'
                    icon={<BugReportIcon />}
                  />
                )}
                {!data.enabled && (
                  <Chip
                    className={classes.dangerFlag}
                    color='secondary'
                    label='DISABLED'
                    icon={<BlockIcon />}
                  />
                )}
              </div>
            )}
            {data.enabled ? (
              <>
                <MUIButton
                  color='primary'
                  size='small'
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  onClick={handleMoreActionsClick}
                >
                  MORE ACTIONS <ExpandMoreIcon />
                </MUIButton>
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMoreActionsClose}
                >
                  <MenuItem
                    onClick={() =>
                      moreActionsMenuSelect(ADMIN_USER_ACTIONS.ASSIGN_AFFILIATE_PROGRAM)
                    }
                  >
                    Assign Affiliate Program
                  </MenuItem>
                  <MenuItem
                    onClick={() => moreActionsMenuSelect(ADMIN_USER_ACTIONS.CREATE_REFERRAL)}
                  >
                    Create Referral
                  </MenuItem>
                  <MenuItem onClick={() => moreActionsMenuSelect(ADMIN_USER_ACTIONS.APPLY_BONUS)}>
                    Apply Bonus
                  </MenuItem>
                  <MenuItem
                    onClick={() => moreActionsMenuSelect(ADMIN_USER_ACTIONS.CREATE_REDEMPTION)}
                  >
                    Create Redemption
                  </MenuItem>
                  {data.fraud_flag ? (
                    <MenuItem
                      onClick={() => moreActionsMenuSelect(ADMIN_USER_ACTIONS.UN_MARK_FRAUD)}
                    >
                      Un-Flag as Fraud
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={() => moreActionsMenuSelect(ADMIN_USER_ACTIONS.MARK_FRAUD)}>
                      Flag as Fraud
                    </MenuItem>
                  )}
                </Menu>
                {edit ? (
                  <EditButton basePath={basePath} record={data} />
                ) : (
                  <ShowButton basePath={basePath} record={data} />
                )}

                <Button onClick={() => updateBotScore(data?.sub)} label='Update Bot Score'>
                  <AndroidIcon />
                </Button>

                <AssignAffiliateDialog
                  show={showAssignAffiliateDialog}
                  setShow={setShowAssignAffiliateDialog}
                  userId={data?.sub}
                  refreshPage={refresh}
                />
                <CreateReferralDialog
                  show={showCreateReferralDialog}
                  setShow={setShowCreateReferralDialog}
                  referrer={data?.sub}
                  refreshPage={refresh}
                />
                <CreateRedemptionDialog
                  show={showCreateRedemptionDialog}
                  setShow={setShowCreateRedemptionDialog}
                  userId={data?.sub}
                  refreshPage={refresh}
                />
                <UrlActionDialog
                  userId={data?.sub}
                  url={`users/${data?.sub}/fraud/1`}
                  show={showMarkFraudDialog}
                  btnClass={classes.deleteBtn}
                  setShow={setShowMarkFraudDialog}
                  method='GET'
                  title='Flag Fraud'
                  description={`Are you sure you want to <b>mark</b> the user <b>[${data?.email}]</b> as <b>fraud</b>`}
                  buttonLabel='Confirm'
                />
                <UrlActionDialog
                  userId={data?.sub}
                  url={`users/${data?.sub}/fraud/0`}
                  show={showUnMarkFraudDialog}
                  btnClass={classes.deleteBtn}
                  setShow={setShowUnMarkFraudDialog}
                  method='GET'
                  title='Un-Flag Fraud'
                  description={`Are you sure you want to <b>un-mark</b> the user <b>[${data?.email}]</b> as <b>fraud</b>`}
                  buttonLabel='Confirm'
                />
                <UrlActionDialog
                  userId={data?.sub}
                  url={`users/${data?.sub}/disable`}
                  show={showDisableDialog}
                  btnClass={classes.deleteBtn}
                  setShow={setShowDisableDialog}
                  method='GET'
                  title='Disable User'
                  description={`Are you sure you want to <b>disable</b> the user <b>[${data?.email}]</b>`}
                  buttonLabel='Disable'
                />
                <ApplyBonusDialog
                  userId={data?.sub}
                  show={showApplyBonusDialog}
                  setShow={setShowApplyBonusDialog}
                  refreshPage={refresh}
                />
                <Button
                  className={classes.deleteBtn}
                  onClick={() => setShowDisableDialog(true)}
                  label='Disable'
                >
                  <BlockIcon />
                </Button>
              </>
            ) : (
              <>
                <UrlActionDialog
                  userId={data?.sub}
                  url={`users/${data?.sub}/enable`}
                  show={showEnableDialog}
                  btnClass={classes.enableBtn}
                  setShow={setShowEnableDialog}
                  method='GET'
                  title='Enable User'
                  description={`Are you sure you want to <b>enable</b> the user <b>[${data?.email}]</b>`}
                  buttonLabel='Enable'
                />
                <Button
                  className={classes.enableBtn}
                  onClick={() => setShowEnableDialog(true)}
                  label='Enable'
                >
                  <VerifiedUserIcon />
                </Button>
              </>
            )}

            <DeleteUserDialog
              userId={data?.sub}
              email={data?.email}
              show={showDeleteDialog}
              btnClass={classes.deleteBtn}
              setShow={setShowDeleteDialog}
              refreshPage={refresh}
            />

            <Button
              className={classes.deleteBtn}
              onClick={() => setShowDeleteDialog(true)}
              label='Delete'
            >
              <DeleteIcon />
            </Button>
          </>
        )}
      </TopToolbar>
    );
  }
);

export function List(props) {
  const classes = useStyles();
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />} filters={userFilters}>
      <Datagrid>
        <TextField sortable={false} source='email' />
        <TextField sortable={false} source='given_name' />
        <TextField sortable={false} source='family_name' />
        <FunctionField
          addLabel
          label='Flags'
          render={(record) => (
            <>
              {!record.enabled && (
                <Chip className={classes.dangerFlagChip} color='secondary' label='DISABLED' />
              )}
              {record.fraud_flag && (
                <Chip className={classes.dangerFlagChip} color='secondary' label='FRAUD' />
              )}
            </>
          )}
        />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit actions={<UserActions edit={false} />} {...props}>
      <UserForm toolbar={<CustomToolbar />} />
    </ReactAdminEdit>
  );
}

export function Show(props) {
  return (
    <ReactAdminShow actions={<UserActions />} {...props}>
      <UserShow {...props} />
    </ReactAdminShow>
  );
}

export function CustomToolbar(props) {
  return (
    <Toolbar {...props} className={useStyles().toolbar}>
      <SaveButton />
    </Toolbar>
  );
}

function UserForm({ record, ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput fullWidth source='family_name' />
      <TextInput fullWidth source='given_name' />
      <TextInput disabled fullWidth source='email' />
      <TextInput fullWidth label='Phone Number (include area code)' source='phone_number' />
      <SelectInput source='gender' choices={GENDER_OPTIONS} fullWidth />
      <DateInput fullWidth source='birthdate' />
      <RegionAutoCompleteInput fullWidth showSelect source='custom:region' />
      <FormDataConsumer>
        {({ formData }) => (
          <span>
            {formData['custom:region'] && (
              <LocaleAutoCompleteInput
                showSelect
                fullWidth
                source='locale'
                regions={formData['custom:region']}
              />
            )}
          </span>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput
            fullWidth
            label={`Location: ${formData['custom:location'] || ''}`}
            source='custom:location'
            reference='locations'
            filter={{ shortCode: formData.locale[0] }}
          >
            <AutocompleteInput resettable optionText='locationName' />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <BooleanInput fullWidth source='phone_number_verified' />
      <BooleanInput disabled fullWidth source='email_verified' />
    </SimpleForm>
  );
}

UserForm.propTypes = {
  record: PropTypes.object
};

function UserShow({ id: userId, record }) {
  const refresh = useRefresh();
  const classes = useStyles();
  const [fraudDetails, setFraudDetails] = React.useState(null);
  const [redemptions, setRedemptions] = React.useState({});
  const [points, setPoints] = React.useState({});
  const [referrals, setReferrals] = React.useState({});
  const [sessions, setSessions] = React.useState({});

  const fetchResource = (resource) => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/users/${userId}/${resource}`, {
      method: 'GET'
    }).then((result) => {
      const { json } = result;
      switch (resource) {
        case 'fraudDetails':
          return setFraudDetails(json);
        case 'redemptions':
          return setRedemptions(json);
        case 'points':
          return setPoints(json);
        case 'referrals':
          return setReferrals(json);
        case 'sessions':
          return setSessions(json);
        default:
          return null;
      }
    });
  };

  React.useEffect(() => {
    fetchResource('fraudDetails');
    fetchResource('redemptions');
    fetchResource('points');
    fetchResource('sessions');
    fetchResource('referrals');
  }, []);

  return (
    <TabbedShowLayout>
      <Tab label='Details'>
        <FunctionField
          addLabel
          label={record.affiliate_program_owner ? 'Affiliate Program Owner' : 'Referred By'}
          render={(rec) =>
            rec.affiliate_program ? (
              <UrlShowField
                source='affiliate_program'
                target='_blank' // New window
                rel='noopener noreferrer' // For security
                resourceName='affiliatePrograms'
                urlSource='affiliate_program_id'
              />
            ) : (
              <UrlShowField
                source='referred_by_email'
                target='_blank' // New window
                rel='noopener noreferrer' // For security
                resourceName='users'
                urlSource='referred_by'
              />
            )
          }
        />
        <TextField label='Account creating using' fullWidth source='signup_flow' />
        <TextField fullWidth label='Account created from' source='custom:client_id' />
        <TextField fullWidth source='family_name' />
        <TextField fullWidth source='given_name' />
        <TextField fullWidth source='email' />
        <TextField fullWidth source='phone_number' />
        <TextField fullWidth source='gender' />
        <DateField fullWidth source='birthdate' />
        <TextField fullWidth source='custom:location' />
        <BooleanField fullWidth source='phone_number_verified' />
        <BooleanField fullWidth source='email_verified' />
      </Tab>
      <Tab label='User Stats' path='userStats'>
        <DateField showTime label='Account Creating Date' fullWidth source='userStats.created_at' />
        <TextField fullWidth label='Signup IP Address' source='userStats.signup_ip_address' />
        <DateField
          showTime
          label='Last Stats Update Date'
          fullWidth
          source='userStats.last_stats_update'
        />
        <NumberField showTime label='Bot Score' fullWidth source='userStats.bot_score' />
        <FunctionField
          addLabel
          label='Extension State'
          render={(rec) => (
            <span>
              {rec.userStats &&
                `${EXTENSION_STATES[rec.userStats.extension_state]} 
                 ${
                   rec.userStats.extension_updated_at
                     ? `(${new Date(+rec.userStats.extension_updated_at).toLocaleString()})`
                     : ''
                 }`}
            </span>
          )}
        />
        <NumberField
          fullWidth
          label='Unique Domains Browsed'
          source='userStats.unique_domains_browsed_count'
        />
        <NumberField fullWidth label='Total Points' source='userStats.total_points' />
        <DateField
          showTime
          label='Last Data Download'
          fullWidth
          source='userStats.last_data_download'
        />
        <DateField
          showTime
          label='Last URL History Push'
          fullWidth
          source='userStats.last_history_push'
        />
        <NumberField label='Total Active Hours' fullWidth source='userStats.total_active_hours' />
      </Tab>
      {fraudDetails && (
        <Tab label='Fraud Check' path='fraud_check'>
          <FunctionField
            label='Bot Score'
            render={(rec) => (rec.userStats ? rec.userStats.bot_score : 0)}
          />
          <FunctionField
            label='Banned Domain Visited'
            render={() => fraudDetails.banned_domains_visited}
          />
          <p>Factors contributing to bot score: </p>
          <FunctionField
            label='High number of urls visited in last 24 hours'
            render={() =>
              fraudDetails?.bot_score_details?.checkNumUrlsVisitedLast24Hours?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkNumUrlsVisitedLast24Hours.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Email contains plus or dot'
            render={() =>
              fraudDetails?.bot_score_details?.checkEmail?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkEmail.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Email contains numbers'
            render={() =>
              fraudDetails?.bot_score_details?.checkNumbersInEmail?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkNumbersInEmail.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Email contains capital letters'
            render={() =>
              fraudDetails?.bot_score_details?.checkCapitalLetters?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkCapitalLetters.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Has not visited enough unique domains'
            render={() =>
              fraudDetails?.bot_score_details?.checkUniqueDomains?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkUniqueDomains.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Has visited banned domain'
            render={() =>
              fraudDetails?.bot_score_details?.checkBannedDomains?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkBannedDomains.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Has very less active hours'
            render={() =>
              fraudDetails?.bot_score_details?.checkActiveHours?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkActiveHours.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='High unique domains to active hours ratio'
            render={() =>
              fraudDetails?.bot_score_details?.checkRatioActiveHoursToUniqueDomain?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkRatioActiveHoursToUniqueDomain.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='IP address is out of North America'
            render={() =>
              fraudDetails?.bot_score_details?.checkIPOutsideNA?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkIPOutsideNA.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Uninstalled extension after last redemption'
            render={() =>
              fraudDetails?.bot_score_details?.checkExtensionUninstalledOnRedemption?.check ? (
                <span className={classes.deleteBtn}>
                  YES [
                  {fraudDetails?.bot_score_details?.checkExtensionUninstalledOnRedemption.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='High value of last redemption (5000)'
            render={() =>
              fraudDetails?.bot_score_details?.checkRedemptionValue?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkRedemptionValue.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Account age is low'
            render={() =>
              fraudDetails?.bot_score_details?.checkAccountAge?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkAccountAge.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label="One of the referee's bot score is high"
            render={() =>
              fraudDetails?.bot_score_details?.checkRefereeBotScore?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkRefereeBotScore.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label="Referrer's bot score is high"
            render={() =>
              fraudDetails?.bot_score_details?.checkReferrerBotScore?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkReferrerBotScore.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label='Successful referrals to account age ratio is high'
            render={() =>
              fraudDetails?.bot_score_details?.checkSuccessfulReferralsVsAccountAge?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkSuccessfulReferralsVsAccountAge.value}
                  ]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
          <FunctionField
            label="User's signup ip is used by other accounts"
            render={() =>
              fraudDetails?.bot_score_details?.checkDuplicateUsersByIP?.check ? (
                <span className={classes.deleteBtn}>
                  YES [{fraudDetails?.bot_score_details?.checkDuplicateUsersByIP.value}]
                </span>
              ) : (
                <span>No</span>
              )
            }
          />
        </Tab>
      )}
      <Tab label='Points' path='points'>
        <NumberField label='Balance' defaultValue={0} fullWidth source='userStats.total_points' />
        <PointsTable userId={userId} points={points} />
      </Tab>
      <Tab label='Redemptions' path='redemptions'>
        <UserRedemptionsTable
          redemptions={redemptions}
          userId={userId}
          refresh={refresh}
          reloadRedemptions={() => fetchResource('redemptions')}
        />
      </Tab>
      <Tab label='Referrals' path='referrals'>
        <ReferralsTable
          referrals={referrals}
          userId={userId}
          reloadReferrals={() => fetchResource('referrals')}
        />
      </Tab>
      {record.affiliate_program_owner && (
        <Tab label='Affiliate Members' path='affiliateMembers'>
          <ArrayField source='affiliate_program_members.Items' label='Program Members'>
            <Datagrid>
              <DateField label='Created at' showTime source='created_at' />
              <UrlShowField
                label='Email'
                source='email'
                target='_blank' // New window
                rel='noopener noreferrer' // For security
                resourceName='users'
                urlSource='id'
              />
              <TextField label='Email Verified' source='email_verified' />
              <TextField label='Phone' source='phone' />
              <TextField label='Fraud Flag' source='fraud_flag' />
            </Datagrid>
          </ArrayField>
          <MoreAffiliateMembersTable
            programId={record.affiliate_program_id}
            LastEvaluatedKey={record?.affiliate_program_members?.LastEvaluatedKey}
          />
        </Tab>
      )}
      <Tab label='Sessions' path='sessions'>
        <SessionsTable sessions={sessions} userId={userId} />
      </Tab>
    </TabbedShowLayout>
  );
}

UserShow.propTypes = {
  record: PropTypes.object,
  id: PropTypes.string
};

function DollarTextField({ source }) {
  const record = useRecordContext();
  return <span>${record && record[source]}</span>;
}
DollarTextField.propTypes = {
  source: PropTypes.string
};

export const DollarField = DollarTextField;

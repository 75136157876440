import * as React from 'react';
import { TextInput, TextField, Datagrid, List as ReactAdminList, DateField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../components/Pagination';

const programFilters = [<TextInput key='name' source='name' label='Search' alwaysOn />];

const useStyles = makeStyles({
  lineBreakField: {
    'white-space': 'pre-line'
  }
});

export function List(props) {
  const classes = useStyles();
  return (
    <ReactAdminList
      {...props}
      bulkActionButtons={false}
      perPage={25}
      pagination={<Pagination />}
      filters={programFilters}
    >
      <Datagrid>
        <DateField showTime sortable={false} source='created_at' />
        <TextField sortable={false} source='user' />
        <TextField sortable={false} source='page' />
        <TextField sortable={false} source='action_type' />
        <TextField className={classes.lineBreakField} sortable={false} source='action_details' />
      </Datagrid>
    </ReactAdminList>
  );
}

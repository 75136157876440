import { defaultTheme } from 'react-admin';

export const darkTheme = {
  palette: {
    primary: {
      main: '#fff'
    },
    secondary: {
      main: '#212121'
    },
    type: 'dark'
  },
  shape: {
    borderRadius: 10
  },
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: 'inherit !important'
      }
    },
    RaMenuItemLink: {
      active: {
        backgroundColor: '#ffffff14'
      }
    },
    MuiLink: {
      root: {
        color: '#5f8dbe',
        textDecoration: 'underline !important'
      }
    },
    MuiCardActions: {
      root: {
        '& .MuiButton-containedPrimary': {
          backgroundColor: '#fff !important'
        }
      }
    },
    RaLoginForm: {
      input: {
        '& .MuiFormLabel-root': {
          color: 'white !important'
        }
      }
    }
  },
  components: {
    ...defaultTheme.components
  }
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#4050b5'
    },
    secondary: {
      light: '#4050b5',
      main: '#3fb6e8',
      dark: '#001064',
      contrastText: '#fff'
    },
    background: {
      default: '#fcfcfe'
    },
    type: 'light'
  },
  shape: {
    borderRadius: 10
  },
  overrides: {
    RaMenuItemLink: {
      active: {
        backgroundColor: '#0000000a'
      }
    },
    MuiLink: {
      root: {
        color: '#4050b5',
        textDecoration: 'underline !important'
      }
    },
    MuiCardActions: {
      root: {
        '& .MuiButton-containedPrimary': {
          backgroundColor: '#3fb6e8 !important'
        }
      }
    }
  },
  components: {
    ...defaultTheme.components
  }
};

import * as React from 'react';
import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  FormDataConsumer,
  required,
  FunctionField,
  SimpleForm,
  FileInput,
  FileField,
  TextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  TextInput,
  ImageField,
  ImageInput,
  DateTimeInput
} from 'react-admin';
import PropTypes from 'prop-types';
import RichTextInput from 'ra-input-rich-text';
import { Button } from '@material-ui/core';
import { useField } from 'react-final-form';
import cloneDeep from 'lodash/cloneDeep';
import { Pagination } from '../components/Pagination';

function arraySwap(arr, i1, i2) {
  const temp = arr[i1];
  const array = [...arr];
  array[i1] = array[i2];
  array[i2] = temp;
  return cloneDeep(array);
}

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />}>
      <Datagrid>
        <TextField sortable={false} source='name' />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <ContentSimpleForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <ContentSimpleForm />
    </ReactAdminEdit>
  );
}

function ContentSimpleForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput validate={[required()]} source='name' fullWidth />
      <BlockInput source='blocks' />
    </SimpleForm>
  );
}

function BlockInput({ source }) {
  const field = useField(source);

  return (
    <ArrayInput source='blocks'>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource, id }) => {
            getSource('');
            const index = Number(id.match(/\d/)[0]);
            const move = (dir) => {
              field.input.onChange(arraySwap(field.input.value, index, index + dir));
            };
            return (
              <div>
                <div style={{ display: 'flex' }}>
                  <SelectInput
                    fullWidth
                    validate={[required()]}
                    allowEmpty
                    label='Type'
                    source={getSource('type')}
                    choices={['content', 'faq', 'giveaway', 'divider', 'carousel'].map((t) => ({
                      id: t,
                      name: t
                    }))}
                  />
                  <Button disabled={index === 0} onClick={() => move(-1)}>
                    Up
                  </Button>
                  <Button disabled={index >= field.input.value.length - 1} onClick={() => move(1)}>
                    Down
                  </Button>
                </div>

                {scopedFormData && scopedFormData.type === 'content' ? (
                  <ContentForm getSource={getSource} />
                ) : null}

                {scopedFormData && scopedFormData.type === 'faq' ? (
                  <FAQForm getSource={getSource} />
                ) : null}

                {scopedFormData && scopedFormData.type === 'giveaway' ? (
                  <GiveawayForm getSource={getSource} />
                ) : null}

                {scopedFormData && scopedFormData.type === 'carousel' ? (
                  <CarousalForm getSource={getSource} formData={scopedFormData} />
                ) : null}
              </div>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
BlockInput.propTypes = {
  source: PropTypes.string
};

function ContentForm({ getSource }) {
  return <RichTextInput label='Content' source={getSource('content')} />;
}
ContentForm.propTypes = {
  getSource: PropTypes.func
};

function FAQForm({ getSource }) {
  return (
    <>
      <TextInput label='Question' fullWidth source={getSource('question')} />

      <RichTextInput label='Content' source={getSource('content')} />
    </>
  );
}

FAQForm.propTypes = {
  getSource: PropTypes.func
};

function GiveawayForm({ getSource }) {
  return (
    <>
      <TextInput label='Title' fullWidth source={getSource('title')} />
      <TextInput fullWidth label='Description' source={getSource('description')} />
      <TextInput fullWidth label='Button Label' source={getSource('button_label')} />
      <TextInput fullWidth label='Button Url' source={getSource('button_url')} />
      <DateTimeInput label='Active Until' fullWidth source={getSource('active_until')} />
      <ImageInput source={getSource('image')} label='Image' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>
    </>
  );
}

GiveawayForm.propTypes = {
  getSource: PropTypes.func
};

const fileSizeValidation = (value) => {
  if (!value) {
    return undefined;
  }

  return value.rawFile.size > 5000000 ? 'File must be less than or equal to 5mb' : undefined;
};

function CarousalForm({ getSource, formData }) {
  return (
    <>
      <SelectInput
        fullWidth
        validate={[required()]}
        allowEmpty
        label='Carousel Slide Type'
        source={getSource('carousel_slide_type')}
        choices={['FTUE', 'Evergreen'].map((t) => ({
          id: t,
          name: t
        }))}
      />
      <TextInput label='Title' fullWidth source={getSource('title')} />
      <TextInput fullWidth label='Description' source={getSource('description')} />
      <TextInput fullWidth label='Button Label' source={getSource('button_label')} />
      <TextInput fullWidth label='Button Url' source={getSource('button_url')} />
      <DateTimeInput label='Active Until' fullWidth source={getSource('active_until')} />

      <SelectInput
        fullWidth
        validate={[required()]}
        allowEmpty
        label='File Type'
        source={getSource('file_type')}
        choices={['image', 'video'].map((t) => ({
          id: t,
          name: t
        }))}
      />

      <FunctionField
        addLabel
        label='File Upload'
        render={() =>
          formData.file_type === 'image' ? (
            <ImageInput source={getSource('image')} label='Image' accept='image/*'>
              <ImageField source='src' title='title' />
            </ImageInput>
          ) : (
            <>
              <SelectInput
                fullWidth
                validate={[required()]}
                allowEmpty
                label='File Source'
                source={getSource('file_src')}
                choices={['url', 'upload'].map((t) => ({
                  id: t,
                  name: t
                }))}
              />
              <FunctionField
                addLabel
                label='File Upload'
                render={() =>
                  formData.file_src === 'upload' ? (
                    <FileInput
                      source={getSource('files')}
                      validate={fileSizeValidation}
                      label='Video'
                      accept='video/mp4,video/x-m4v,video/*,video/mov'
                    >
                      <FileField source='src' title='title' />
                    </FileInput>
                  ) : (
                    <TextInput fullWidth label='Url' source={getSource('file.src')} />
                  )
                }
              />
              <p>*Files must be 5mb or less</p>
            </>
          )
        }
      />

      <SelectInput
        fullWidth
        validate={[required()]}
        allowEmpty
        label='Theme Type'
        source={getSource('theme')}
        choices={['blue', 'white'].map((t) => ({
          id: t,
          name: t
        }))}
      />

      <SelectInput
        fullWidth
        validate={[required()]}
        allowEmpty
        label='Copy position'
        source={getSource('copy_position')}
        choices={['left', 'right'].map((t) => ({
          id: t,
          name: t
        }))}
      />
    </>
  );
}

CarousalForm.propTypes = {
  getSource: PropTypes.func,
  formData: PropTypes.object
};

import * as React from 'react';
import { Link } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

export function UrlShowField(props) {
  const { source, target, rel, resourceName, urlSource } = props;
  const record = useRecordContext(props);
  const value = record && record[source];
  let urlSourceValue = value;
  if (urlSource) {
    urlSourceValue = record && record[urlSource];
  }
  const newURL = `${window.location.protocol}//${window.location.host}/#/${resourceName}/${urlSourceValue}/show`;

  if (value === null) {
    return null;
  }

  return (
    <Link href={newURL} target={target} rel={rel}>
      {value}
    </Link>
  );
}

UrlShowField.propTypes = {
  source: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  resourceName: PropTypes.string,
  urlSource: PropTypes.string
};

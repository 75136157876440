import React, { useState, useEffect } from 'react';
import { TextField, DateField, Button } from 'react-admin';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

const useStyles = makeStyles({
  showMoreBtn: {
    width: '100%'
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: '600'
    }
  }
});

export function SessionsTable({ sessions, userId }) {
  const [sessionsPaginationKey, setSessionsPaginationKey] = useState(sessions.LastEvaluatedKey);
  const [moreSessions, setMoreSessions] = useState(sessions.Items);
  const classes = useStyles();

  useEffect(() => {
    setMoreSessions(sessions.Items);
    setSessionsPaginationKey(sessions.LastEvaluatedKey);
  }, [sessions]);

  const getMoreSessions = () => {
    fetchJson(
      `${
        process.env.REACT_APP_API_URL
      }${STAGE}/admin/users/${userId}/sessions?paginationKey=${JSON.stringify(
        sessionsPaginationKey
      )}`,
      {
        method: 'GET'
      }
    ).then((result) => {
      const resJson = result.json;
      resJson.Items = resJson.Items.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
      setMoreSessions(moreSessions.concat(resJson.Items));
      setSessionsPaginationKey(resJson.LastEvaluatedKey);
    });
  };

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Started At</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Auth Client</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moreSessions && moreSessions.length > 0 ? (
              moreSessions.map((row) => (
                <TableRow hover key={row.created_at}>
                  <TableCell>
                    <DateField record={row} showTime source='created_at' />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} source='ip_address' />
                  </TableCell>
                  <TableCell>
                    <TextField record={row} source='client_id' />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={3}>
                  No sessions created by user
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {sessionsPaginationKey && (
        <Button className={classes.showMoreBtn} onClick={() => getMoreSessions()} label='Show More'>
          <ExpandMoreIcon />
        </Button>
      )}
    </>
  );
}

SessionsTable.propTypes = {
  sessions: PropTypes.object,
  userId: PropTypes.string
};

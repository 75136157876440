import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  Datagrid,
  ShowButton,
  CreateButton,
  ExportButton,
  Button,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  required,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  DateTimeInput,
  TopToolbar,
  useRefresh,
  Pagination,
  DateField
} from 'react-admin';
import { Button as MUIButton, Link } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BackupIcon from '@material-ui/icons/Backup';
import { makeStyles } from '@material-ui/core/styles';
import { UploadAffiliateBrandOffersDialog } from '../components/UploadAffiliateBrandOffersDialog';
import { FetchAffiliateBrandOffersDialog } from '../components/FetchAffiliateBrandOffersDialog';
import { urlValidator } from '../utils/validators';

const brandFilters = [
  <ReferenceInput
    key='brand'
    fullWidth
    source='brandId'
    label='Search Brand'
    reference='brands'
    alwaysOn
    filterToQuery={(searchText) => ({ name: searchText })}
    enableGetChoices={({ name }) => name.length > 3}
  >
    <AutocompleteInput optionText='name' optionValue='id' />
  </ReferenceInput>,
  <SelectInput
    key='show'
    alwaysOn
    allowEmpty={false}
    source='show'
    choices={[
      { id: 'active', name: 'Active', selected: true },
      { id: 'expired', name: 'Expired' },
      { id: 'future', name: 'Future' },
      { id: 'all', name: 'All' }
    ]}
  />
];

const useStyles = makeStyles({
  muiBtn: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '13px',
    textDecoration: 'none',
    '& span.MuiButton-label svg.MuiSvgIcon-root': {
      fontSize: '20px',
      marginRight: '5px',
      marginBottom: '2px'
    }
  }
});

function BrandActions() {
  const classes = useStyles();
  const [showFetchOfferDialog, setShowFetchOffersDialog] = React.useState(false);
  const [showUploadOffersDialog, setShowUploadOffersDialog] = React.useState(false);
  const [csvFile, setCsvFile] = React.useState('');
  const refresh = useRefresh();
  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton />
      <MUIButton component='label' color='primary' className={classes.muiBtn}>
        <BackupIcon />
        <label htmlFor='uploadOffers'>
          Upload Offers
          <input
            id='uploadOffers'
            aria-label='uploadOffers'
            type='file'
            accept='.csv'
            onChange={(e) => {
              const reader = new FileReader();
              reader.addEventListener('load', (event) => {
                setCsvFile(event.target.result);
                setShowUploadOffersDialog(true);
              });
              reader.readAsText(e.target.files[0]);
            }}
            hidden
          />
        </label>
      </MUIButton>
      <Button onClick={() => setShowFetchOffersDialog(true)} label='Fetch Offers'>
        <CloudDownloadIcon />
      </Button>
      <FetchAffiliateBrandOffersDialog
        show={showFetchOfferDialog}
        setShow={setShowFetchOffersDialog}
      />
      <UploadAffiliateBrandOffersDialog
        brandOffersCsv={csvFile}
        show={showUploadOffersDialog}
        setShow={setShowUploadOffersDialog}
        refresh={refresh}
      />
    </TopToolbar>
  );
}

export function List(props) {
  return (
    <ReactAdminList
      {...props}
      perPage={25}
      actions={<BrandActions />}
      filters={brandFilters}
      pagination={<Pagination />}
    >
      <Datagrid>
        <TextField sortable={false} source='name' />
        <FunctionField
          addLabel
          label='Brand'
          render={(record) => (
            <Link href={`/#/brands/${record.brand_id}/show`} rel='noreferrer' target='_blank'>
              {record.brand_name}
            </Link>
          )}
        />
        <TextField sortable={false} source='featured' />
        <FunctionField
          addLabel
          label='Offer Type'
          render={(record) => (record.discount_code_offer ? 'CODE' : 'LINK')}
        />
        <DateField showTime sortable={false} source='start_date' />
        <DateField showTime sortable={false} source='active_until' />
        <TextField sortable={false} label='Times Offer Copied' source='offer_copied_count' />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <BrandForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <BrandForm />
    </ReactAdminEdit>
  );
}

function BrandForm(props) {
  return (
    <SimpleForm {...props}>
      <ReferenceInput
        key='brand'
        validate={required()}
        fullWidth
        source='brand_id'
        label='Search Brand'
        reference='brands'
        alwaysOn
        filterToQuery={(searchText) => ({ name: searchText })}
        enableGetChoices={({ name }) => name.length > 3}
      >
        <AutocompleteInput optionText='name' optionValue='id' />
      </ReferenceInput>
      <BooleanInput fullWidth source='featured' />
      <BooleanInput label='Discount Code Offer' source='discount_code_offer' />
      <TextInput validate={required()} fullWidth label='Title' source='name' />
      <TextInput validate={required()} fullWidth label='Description' source='description' />
      <NumberInput
        validate={required()}
        fullWidth
        label='Commission Rate'
        source='commission_rate'
      />
      <TextInput fullWidth label='Offer Link' source='link' validate={urlValidator} />
      <FormDataConsumer>
        {({ formData }) =>
          formData.discount_code_offer && (
            <TextInput required fullWidth label='Discount Code' source='code' />
          )
        }
      </FormDataConsumer>
      <DateTimeInput fullWidth label='Start Date' source='start_date' />
      <DateTimeInput fullWidth source='active_until' />
    </SimpleForm>
  );
}

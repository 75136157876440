import React, { useState } from 'react';
import { DateField, Button } from 'react-admin';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
// eslint-disable-next-line import/no-extraneous-dependencies
import { REWARDS_REDEMPTION_STATUS, REWARD_TYPES } from 'surf-api/utils/enumerable';
import { REDEMPTIONS_STATES } from '../utils/constants';
import { UrlShowField } from './UrlShowField';
import { ProcessRedemptionDialog } from './ProcessRedemptionDialog';

const useStyles = makeStyles({
  showMoreBtn: {
    width: '100%'
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: '600'
    }
  },
  failedState: {
    background: 'red',
    color: 'white',
    height: '27px'
  },
  unprocessedState: {
    background: 'yellow',
    color: 'black',
    height: '27px'
  },
  processedState: {
    background: 'green',
    color: 'white',
    height: '27px'
  },
  pendingState: {
    background: 'grey',
    color: 'white',
    height: '27px'
  }
});

export function UserRedemptionsTable({ redemptions, reloadRedemptions }) {
  const [redemptionId, setRedemptionId] = useState('');
  const [showProcessDialog, setShowProcessDialog] = useState(false);
  const classes = useStyles();
  return (
    <>
      <ProcessRedemptionDialog
        show={showProcessDialog}
        setShow={setShowProcessDialog}
        reloadData={reloadRedemptions}
        redemptionId={redemptionId}
      />
      <TableContainer>
        <Table size='small'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Redemption ID</TableCell>
              <TableCell>Redeemed At</TableCell>
              <TableCell>Reward</TableCell>
              <TableCell>Reward Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Failure Reason</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {redemptions && redemptions.length > 0 ? (
              redemptions.map((row) => (
                <TableRow hover key={row.time_token}>
                  <TableCell>
                    <UrlShowField
                      record={row}
                      label='Redemption ID'
                      source='id'
                      target='_blank' // New window
                      rel='noopener noreferrer' // For security
                      resourceName='redemptions'
                    />
                  </TableCell>
                  <TableCell>
                    <DateField record={row} label='Redeemed at' showTime source='created_at' />
                  </TableCell>
                  <TableCell>
                    <>
                      {row.is_giveaway && <span>{row.giveaway_name}</span>}
                      {row.is_discount_code
                        ? `${row.reward_name} (${row.discount_code})`
                        : row.reward_name}
                    </>
                  </TableCell>
                  <TableCell>{row.reward_type}</TableCell>
                  <TableCell>
                    {row.valueType === 'percent' ? (
                      <span>{row.value}% off</span>
                    ) : (
                      <span>
                        ${row.value}{' '}
                        {row.reward_type === REWARD_TYPES.SUBSIDIZED_REWARD ? 'off' : ''}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      className={
                        classes[`${REDEMPTIONS_STATES[row.state || 0].toLowerCase()}State`]
                      }
                      color='secondary'
                      label={REDEMPTIONS_STATES[row.state || 0]}
                    />
                  </TableCell>
                  <TableCell>
                    {row.state === REWARDS_REDEMPTION_STATUS.FAILED ||
                    row.state === REWARDS_REDEMPTION_STATUS.UNPROCESSED
                      ? row.failure_reason
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={row.state === 1 || row.state === 2}
                      onClick={() => {
                        setRedemptionId(row.id);
                        setShowProcessDialog(true);
                      }}
                      label='Process Redemption'
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={7}>
                  No redemptions done by user
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

UserRedemptionsTable.propTypes = {
  redemptions: PropTypes.array,
  reloadRedemptions: PropTypes.func
};

import React, { useState } from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  List as ReactAdminList,
  Create as ReactAdminCreate,
  SaveButton,
  Toolbar,
  SelectInput,
  BooleanInput
} from 'react-admin';
import PropTypes from 'prop-types';
import { useListContext } from 'ra-core';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { Pagination } from '../components/Pagination';

function PointEarnWidget({ uniqueMulti, repeatMulti, maxAge, bonusMulti }) {
  const [repeat, setRepeat] = useState(10);
  const [unique, setUnique] = useState(10);
  const [age, setAge] = useState(0);
  const uniqueSiteEarnAmount = unique * uniqueMulti;
  const repeatSiteEarnAmount = repeat * repeatMulti;
  const earnedPoints = Math.floor(Math.min(uniqueSiteEarnAmount + repeatSiteEarnAmount, 20));
  const multiplier = -1 * (age / maxAge) ** 2 + 1;
  const bonus = earnedPoints * multiplier * bonusMulti;
  const bonusPoints = Math.ceil(bonus / 5) * 5;
  return (
    <div style={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
      <h2>Daily Point Calculator</h2>
      <label htmlFor='uniqueUrls'>
        <span style={{ paddingRight: '16px' }}>Unique URLs</span>
        <input
          name='uniqueUrls'
          aria-label='uniqueUrls'
          type='range'
          min='0'
          max='100'
          value={unique}
          step='1'
          onChange={(e) => setUnique(parseInt(e.currentTarget.value))}
        />
        <span>{unique}</span>
      </label>
      <label htmlFor='repeatUrls'>
        <span style={{ paddingRight: '16px' }}>Repeat URLs</span>
        <input
          name='repeatUrls'
          aria-label='repeatUrls'
          type='range'
          min='0'
          max='100'
          value={repeat}
          step='1'
          onChange={(e) => setRepeat(parseInt(e.currentTarget.value))}
        />
        <span>{repeat}</span>
      </label>
      <p>
        Base earn: <span style={{ fontWeight: 'bold' }}>{earnedPoints}</span>
      </p>
      <label htmlFor='accountAge'>
        <span style={{ paddingRight: '16px' }}>Account age (days)</span>
        <input
          name='accountAge'
          aria-label='accountAge'
          type='range'
          min='0'
          max='30'
          value={age}
          step='1'
          onChange={(e) => setAge(parseInt(e.currentTarget.value))}
        />
        <span>{age}</span>
      </label>
      <p>
        New user bonus earn:{' '}
        <span style={{ fontWeight: 'bold' }}>{bonusPoints < 0 ? 0 : bonusPoints}</span>
      </p>
      <p>
        Total earn:{' '}
        <span style={{ fontWeight: 'bold' }}>
          {(bonusPoints < 0 ? 0 : bonusPoints) + earnedPoints}
        </span>
      </p>
    </div>
  );
}

function ListWidget() {
  const { data, loading } = useListContext();
  if (loading) {
    return null;
  }
  const unique = parseFloat(data.UNIQUE_SITE_EARN_MULTIPLIER?.value);
  const repeat = parseFloat(data.REPEAT_SITE_EARN_MULTIPLIER?.value);
  const maxAge = parseFloat(data.NEW_USER_BONUS_DAY_LIMIT?.value);
  const bonusMulti = parseFloat(data.NEW_USER_BONUS_MULTIPLIER?.value);
  return (
    <PointEarnWidget
      uniqueMulti={unique}
      repeatMulti={repeat}
      maxAge={maxAge}
      bonusMulti={bonusMulti}
    />
  );
}

const settingsFilters = [<TextInput key='name' source='name' label='Setting Name' alwaysOn />];

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} filters={settingsFilters} pagination={<Pagination />}>
      <>
        <ListWidget />
        <Datagrid>
          <TextField sortable={false} source='name' />
          <TextField sortable={false} source='type' />
          <TextField sortable={false} source='value' />
          <TextField sortable={false} source='isPublic' />
          <ShowButton />
        </Datagrid>
      </>
    </ReactAdminList>
  );
}
PointEarnWidget.propTypes = {
  uniqueMulti: PropTypes.number,
  repeatMulti: PropTypes.number,
  maxAge: PropTypes.number,
  bonusMulti: PropTypes.number
};

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <SettingsEditForm />
    </ReactAdminEdit>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <SettingsCreateForm />
    </ReactAdminCreate>
  );
}

function isNumeric(str) {
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

function valueValidator(value, values) {
  switch (values.type) {
    case 'number': {
      return isNumeric(value) ? null : 'Needs to be a valid float';
    }
    case 'boolean': {
      return value !== 'true' && value !== 'false' ? "Needs to be either 'true' or 'false'" : null;
    }
    default: {
      return null;
    }
  }
}

function useValue(name) {
  const settings = useSelector((state) => state.admin.resources.settings.data);
  const form = useFormState();
  if (form.values.name === name) {
    return form.values.value;
  }
  return settings[name] && settings[name].value;
}

function ConnectedPointWidget() {
  const unique = useValue('UNIQUE_SITE_EARN_MULTIPLIER');
  const repeat = useValue('REPEAT_SITE_EARN_MULTIPLIER');
  const maxAge = useValue('NEW_USER_BONUS_DAY_LIMIT');
  const bonusMulti = useValue('NEW_USER_BONUS_MULTIPLIER');
  return (
    <PointEarnWidget
      uniqueMulti={unique}
      repeatMulti={repeat}
      maxAge={maxAge}
      bonusMulti={bonusMulti}
    />
  );
}

function SettingsEditForm(props) {
  return (
    <SimpleForm {...props} toolbar={<SettingsEditToolbar />}>
      <ConnectedPointWidget />
      <TextField source='name' fullWidth />
      <TextField source='type' fullWidth />
      <TextInput source='value' required fullWidth validate={valueValidator} />
      <BooleanInput fullWidth source='isPublic' />
    </SimpleForm>
  );
}

function SettingsEditToolbar(props) {
  const { pristine, invalid } = props;
  return (
    <Toolbar {...props}>
      <SaveButton disabled={pristine || invalid} />
    </Toolbar>
  );
}

SettingsEditToolbar.propTypes = {
  pristine: PropTypes.bool,
  invalid: PropTypes.bool
};

function SettingsCreateForm(props) {
  const choices = [
    { id: 'string', name: 'string' },
    { id: 'boolean', name: 'boolean' },
    { id: 'number', name: 'number' }
  ];
  return (
    <SimpleForm {...props}>
      <TextInput source='name' fullWidth />
      <SelectInput source='type' fullWidth choices={choices} />
      <TextInput source='value' required fullWidth validate={valueValidator} />
      <BooleanInput fullWidth source='isPublic' />
    </SimpleForm>
  );
}

import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import * as BlastNotifications from './resources/blastNotifications';
import * as AppMetrics from './resources/appMetrics';
import * as Rewards from './resources/rewards';
import * as Region from './resources/regions';
import * as FavoriteableBrand from './resources/favoriteableBrands';
import * as Brand from './resources/brands';
import * as BrandOffers from './resources/brandOffers';
import * as Bonus from './resources/bonuses';
import * as User from './resources/users';
import * as MassUserActions from './resources/massUserActions';
import * as Content from './resources/content';
import * as Settings from './resources/settings';
import * as Redemptions from './resources/redemptions';
import * as TenantRedemptions from './resources/tenantRedemptions';
import * as GiveawayRedemptions from './resources/giveawayRedemptions';
import * as AffiliateProgram from './resources/affiliatePrograms';
import * as AuditLog from './resources/auditLogs';
import { isAuthorized } from './utils/roles';
import CustomLayout from './layout/layout';
import { useThemeState } from './layout/themeStateProvider';
import { darkTheme, lightTheme } from './layout/themes';
import CustomLoginPage from './resources/login';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
  onMissingKey: (key) => key
});

const resources = [
  {
    name: 'appMetrics',
    component: (
      <Resource options={{ label: 'App Metrics' }} name='appMetrics' list={AppMetrics.List} />
    )
  },
  {
    name: 'redemptions',
    component: (
      <Resource
        options={{ label: 'Redemptions' }}
        name='redemptions'
        list={Redemptions.List}
        show={Redemptions.Show}
      />
    )
  },
  {
    name: 'tenantRedemptions',
    component: (
      <Resource
        options={{ label: 'Tenant Redemptions' }}
        name='tenantRedemptions'
        list={TenantRedemptions.List}
        show={TenantRedemptions.Show}
      />
    )
  },
  {
    name: 'giveawayRedemptions',
    component: (
      <Resource
        options={{ label: 'Giveaway Redemptions' }}
        name='giveawayRedemptions'
        list={GiveawayRedemptions.List}
      />
    )
  },
  {
    name: 'rewards',
    component: (
      <Resource name='rewards' list={Rewards.List} edit={Rewards.Edit} create={Rewards.Create} />
    )
  },
  {
    name: 'regions',
    component: (
      <Resource name='regions' list={Region.List} edit={Region.Edit} create={Region.Create} />
    )
  },
  {
    name: 'affiliatePrograms',
    component: (
      <Resource
        name='affiliatePrograms'
        options={{ label: 'Affiliate Programs' }}
        list={AffiliateProgram.List}
        edit={AffiliateProgram.Edit}
        create={AffiliateProgram.Create}
      />
    )
  },
  {
    name: 'favoriteableBrands',
    component: (
      <Resource
        name='favoriteableBrands'
        options={{ label: 'Favoriteable Brands' }}
        list={FavoriteableBrand.List}
        edit={FavoriteableBrand.Edit}
        create={FavoriteableBrand.Create}
      />
    )
  },
  {
    name: 'brands',
    component: <Resource name='brands' list={Brand.List} edit={Brand.Edit} create={Brand.Create} />
  },
  {
    name: 'brandOffers',
    component: (
      <Resource
        name='brandOffers'
        options={{ label: 'Brand Offers' }}
        list={BrandOffers.List}
        edit={BrandOffers.Edit}
        create={BrandOffers.Create}
      />
    )
  },
  {
    name: 'bonuses',
    component: <Resource name='bonuses' list={Bonus.List} edit={Bonus.Edit} create={Bonus.Create} />
  },
  {
    name: 'users',
    component: <Resource name='users' list={User.List} edit={User.Edit} show={User.Show} />
  },
  {
    name: 'massUserActions',
    component: (
      <Resource
        options={{ label: 'Mass User Actions' }}
        name='massUserActions'
        list={MassUserActions.List}
        create={MassUserActions.Create}
      />
    )
  },
  {
    name: 'content',
    component: (
      <Resource
        options={{ label: 'Content' }}
        name='content'
        list={Content.List}
        edit={Content.Edit}
        create={Content.Create}
      />
    )
  },
  {
    name: 'settings',
    component: (
      <Resource
        name='settings'
        list={Settings.List}
        edit={Settings.Edit}
        create={Settings.Create}
      />
    )
  },
  {
    name: 'blastNotifications',
    component: (
      <Resource
        name='blastNotifications'
        options={{ label: 'Blast Notifications' }}
        list={BlastNotifications.List}
        create={BlastNotifications.Create}
      />
    )
  },
  {
    name: 'auditLogs',
    component: <Resource name='auditLogs' options={{ label: 'Audit Logs' }} list={AuditLog.List} />
  },
  {
    name: 'locations',
    component: <Resource name='locations' />
  }
];

function App() {
  const { theme } = useThemeState();

  return (
    <Admin
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      theme={theme === 'light' ? lightTheme : darkTheme}
      layout={CustomLayout}
      loginPage={CustomLoginPage}
    >
      {(permissions) =>
        resources.filter((r) => isAuthorized(permissions, r.name)).map((r) => r.component)
      }
    </Admin>
  );
}

export default App;

import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, TextInput, showNotification } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Form } from 'react-final-form';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export const DeleteUserDialog = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ show, setShow, userId, email, btnClass, showNotification }) => {
    const [reasonForDeletion, setReasonForDeletion] = React.useState('');
    const history = useHistory();
    const handleCloseClick = () => {
      setReasonForDeletion('');
      setShow(false);
    };

    const submit = async () => {
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/users/${userId}`, {
        method: 'DELETE',
        body: JSON.stringify({ reasonForDeletion })
      })
        .then(() => {
          setShow(false);
          showNotification('User has been deleted successfully', 'success');
          history.push('/users');
        })
        .catch(() => ({ email: 'Error: Could not delete user.' }));
    };

    return (
      <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label='Delete User'>
        <DialogTitle>Delete User</DialogTitle>
        <Form
          onSubmit={submit}
          render={(formProps) => (
            <>
              <DialogContent>
                <div>
                  Are you sure you want to <b>delete</b> the user <b>[{email}]</b>
                </div>
                <TextInput
                  onChange={(e) => setReasonForDeletion(e.target.value)}
                  fullWidth
                  label='Reason for Deletion'
                  source='email'
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label='Delete'
                  className={btnClass}
                  type='submit'
                  onClick={formProps.handleSubmit}
                />
                <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    );
  }
);

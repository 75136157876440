// @see https://regexr.com/713dg for tests

const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(http?s:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const DOMAIN_REGEX =
  // eslint-disable-next-line no-useless-escape
  /(?!^,)^((^|, )([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?))+$/;

export const urlValidator = (url) => {
  const regex = new RegExp(URL_REGEX);
  const valid = regex.test(url);

  return valid ? null : 'URL not valid';
};

export const domainValidator = (url) => {
  const regex = new RegExp(DOMAIN_REGEX);
  const valid = regex.test(url);

  return valid ? null : 'Domain list not valid. It should be in format [domain1.com, domain2.com]';
};

import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, showNotification } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { fetchJson } from '../dataProvider';
import { AutoCompleteInput } from './AutoCompleteInput';
import { STAGE } from '../utils/constants';

const useStyles = makeStyles({
  mt20: {
    marginTop: '20px'
  },
  error: {
    color: 'red',
    marginTop: '10px',
    fontSize: '15px'
  }
});

export const AssignAffiliateDialog = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ show, setShow, userId, showNotification, refreshPage }) => {
    const history = useHistory();
    const classes = useStyles();
    const [selectedProgram, setSelectedProgram] = React.useState('');
    const [error, setError] = React.useState('');
    const handleCloseClick = () => {
      setError('');
      setSelectedProgram('');
      setShow(false);
    };

    const selectProgram = (program) => {
      setError('');
      setSelectedProgram(program);
    };

    const createAssignment = async () => {
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/users/${userId}/affiliateProgram`, {
        method: 'POST',
        body: JSON.stringify({
          programId: selectedProgram.id
        })
      })
        .then(() => {
          setShow(false);
          showNotification('Affiliate program assigned successfully', 'success');
          refreshPage();
          history.push(`/users/${userId}/show`);
          history.go();
        })
        .catch((e) => {
          setError(e.body);
        });
    };

    const submit = async () => {
      if (!selectedProgram) {
        return setError('The affiliate program is required');
      }

      setError('');
      createAssignment();
      return null;
    };

    return (
      <Dialog
        fullWidth
        open={show}
        onClose={handleCloseClick}
        aria-label='Assign Affiliate Program'
      >
        <DialogTitle>Assign Affiliate Program</DialogTitle>
        <Form
          onSubmit={submit}
          render={(formProps) => (
            <>
              <DialogContent>
                <AutoCompleteInput
                  fullWidth
                  label='Program'
                  source='program'
                  replaceUrlSearchVal
                  url='affiliatePrograms?filter=%7B%22name%22%3A%22searchVal%22%7D&perPage=25'
                  onChange={selectProgram}
                />
                {error && <div className={classes.error}>{error}</div>}
              </DialogContent>
              <DialogActions>
                <Button label='Assign' type='submit' onClick={formProps.handleSubmit} />
                <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    );
  }
);

import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, showNotification } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { fetchJson } from '../dataProvider';
import { AutoCompleteInput } from './AutoCompleteInput';
import { STAGE } from '../utils/constants';

const useStyles = makeStyles({
  mt20: {
    marginTop: '20px'
  },
  error: {
    color: 'red',
    marginTop: '10px',
    fontSize: '15px'
  }
});

export const CreateRedemptionDialog = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ show, setShow, userId, showNotification, refreshPage }) => {
    const history = useHistory();
    const classes = useStyles();
    const [selectedReward, setSelectedReward] = React.useState('');
    const [selectedDenomination, setSelectedDenomination] = React.useState(-1);
    const [error, setError] = React.useState('');
    const handleCloseClick = () => {
      setError('');
      setSelectedReward(false);
      setSelectedDenomination(0);
      setShow(false);
    };

    const selectReward = (reward) => {
      setError('');
      setSelectedReward(reward);
    };

    const createRedemption = async () => {
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/redemptions/create`, {
        method: 'POST',
        body: JSON.stringify({
          rewardId: selectedReward.id,
          denominationIndex: selectedDenomination,
          userId
        })
      })
        .then(() => {
          setShow(false);
          showNotification('Reward redemption created successfully', 'success');
          refreshPage();
          history.push(`/users/${userId}/show/redemptions`);
          history.go();
        })
        .catch((e) => {
          setError(e.body);
        });
    };

    const submit = async () => {
      if (!selectedReward) {
        return setError('The reward is required');
      }

      if (selectedDenomination === -1) {
        return setError('The reward denomination is required');
      }

      setError('');
      createRedemption();
      return null;
    };

    return (
      <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label='Create Redemption'>
        <DialogTitle>Create Redemption</DialogTitle>
        <Form
          onSubmit={submit}
          render={(formProps) => (
            <>
              <DialogContent>
                <AutoCompleteInput
                  fullWidth
                  label='Reward'
                  source='reward'
                  replaceUrlSearchVal
                  url='rewards?filter=%7B%22name%22%3A%22searchVal%22%7D&perPage=25'
                  onChange={selectReward}
                />
                {selectedReward && (
                  <FormControl className={classes.mt20} fullWidth variant='outlined'>
                    <InputLabel>Denomination</InputLabel>
                    <Select
                      label='Denomination'
                      onChange={(event) => setSelectedDenomination(event.target.value)}
                      defaultValue=''
                    >
                      {selectedReward.denominations.map((i, k) => (
                        <MenuItem key={i.value} value={k}>
                          {i.value} ({i.cost} Points)
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {error && <div className={classes.error}>{error}</div>}
              </DialogContent>
              <DialogActions>
                <Button label='Create' type='submit' onClick={formProps.handleSubmit} />
                <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    );
  }
);

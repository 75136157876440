import * as React from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';
import { csvToJson } from '../utils/helpers';

export const UploadAffiliateBrandsDialog = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ show, setShow, refresh, brandsCsv, showNotification }) => {
    const uploadBrands = async (brands) =>
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/brands/affiliateBrands`, {
        method: 'POST',
        body: JSON.stringify({ brands })
      });

    React.useEffect(async () => {
      if (show) {
        const brands = csvToJson(brandsCsv);
        await uploadBrands(brands);
        setShow(false);
        refresh();
        showNotification('All brands have been successfully uploaded', 'success');
      }
    }, [show]);

    return (
      <Dialog fullWidth open={show} aria-label='Upload Brands'>
        <DialogTitle>Upload Affiliate Brands</DialogTitle>
        <DialogContent>
          <div>Please wait while we upload the brands</div>
          <div>
            <br />
            <LinearProgress />
          </div>
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  }
);

import * as React from 'react';
import { AutocompleteArrayInput, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function LocaleAutoCompleteInput({ source, validate, regions = [], showSelect, ...props }) {
  const [locales, setLocales] = React.useState([]);
  const fetchOptions = (region) => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/regions/${region}`)
      .then((resp) => resp.json)
      .then(async (response) => {
        const countries = response.countries.map((c) => ({ id: c.id, name: c.name }));
        setLocales((prev) => [...prev, ...countries]);
      });
  };

  React.useEffect(() => {
    if (regions) {
      setLocales([]);
      if (!Array.isArray(regions)) {
        fetchOptions(regions);
      } else {
        regions.forEach((region) => {
          fetchOptions(region);
        });
      }
    }
  }, [regions]);

  return (
    <div>
      {showSelect ? (
        <SelectInput
          alwaysOn
          allowEmpty={false}
          key={source}
          source={source}
          choices={locales}
          helperText=''
          {...props}
        />
      ) : (
        <AutocompleteArrayInput
          fullWidth
          alwaysOn
          source={source}
          choices={locales}
          validate={validate}
          {...props}
        />
      )}
    </div>
  );
}

LocaleAutoCompleteInput.propTypes = {
  source: PropTypes.string,
  validate: PropTypes.func,
  regions: PropTypes.array,
  showSelect: PropTypes.bool
};

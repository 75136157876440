import * as React from 'react';
import { Notification, LoginForm } from 'react-admin';
import { Card, Container, makeStyles, Typography, Box } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  bgContainer: {
    overflow: 'hidden',
    zIndex: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  bgGradient: {
    position: 'absolute',
    objectFit: 'cover',
    maxWidth: 'none',
    left: '-600px',
    top: '-500px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '95vh'
  },
  main: {
    overflowY: 'auto',
    flex: '1 1 0%',
    zIndex: 10,
    position: 'relative'
  },
  cardContainer: {
    padding: '4rem 1.25rem 4rem 1.25rem',
    minHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: '55px',
    margin: 'auto',
    display: 'block',
    paddingBottom: '1rem'
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    paddingBottom: '1rem'
  },
  card: {
    padding: '3rem 1rem 3rem 1rem'
  }
});

function CustomLoginPage({ theme }) {
  const initialTheme = createTheme(theme);
  const classes = useStyles();
  return (
    <ThemeProvider theme={initialTheme}>
      <Container maxWidth='xs' className={classes.root}>
        <div className={classes.bgContainer}>
          <img alt='login background' src='/bg.png' className={classes.bgGradient} />
        </div>
        <main className={classes.main}>
          <Container maxWidth='md' className={classes.cardContainer}>
            <Card className={classes.card}>
              <Box className={classes.logoBox}>
                <div>
                  <img src='logo.png' alt='logo' className={classes.logo} />
                  <Typography variant='h6' color='inherit' className={classes.title}>
                    Surf Admin Panel
                  </Typography>
                </div>
              </Box>
              <LoginForm />
            </Card>
          </Container>
        </main>
      </Container>
      <Notification />
    </ThemeProvider>
  );
}

CustomLoginPage.propTypes = {
  theme: PropTypes.object
};

export default CustomLoginPage;

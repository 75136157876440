export function jsonToCsv(json) {
  const replacer = (key, value) => (value === null ? '' : value);
  const header = Object.keys(json[0]);
  const csv = [
    header.join(','),
    ...json.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    )
  ].join('\r\n');
  return csv;
}

export function csvToJson(csv) {
  const data = csv.replace('\r', '');
  const lines = data.split('\n');
  const header = lines.shift().split(',');
  return lines.map((line) => {
    const bits = line.split(',');
    const obj = {};
    header.forEach((h, i) => {
      try {
        obj[h] = bits[i] !== '' ? unescape(JSON.parse(bits[i])) : null;
      } catch (e) {
        obj[h] = bits[i] !== '' ? unescape(bits[i]) : null;
      }
    });
    return obj;
  });
}

export function downloadCSV(csv, file) {
  const encodedUri = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const fileName = `${file}_${new Date().toLocaleDateString().replace('/', '_')}.csv`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
}

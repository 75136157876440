import * as React from 'react';
import { Layout, AppBar } from 'react-admin';
import { Box, Typography, IconButton, makeStyles } from '@material-ui/core';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { useThemeState } from './themeStateProvider';

function ThemeToggler() {
  const { theme, setTheme } = useThemeState();
  const handleThemeChange = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  return (
    <IconButton style={{ color: theme === 'dark' ? 'white' : 'black' }} onClick={handleThemeChange}>
      {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
}

const useStyles = makeStyles({
  logo: {
    maxWidth: '35px',
    marginRight: '10px'
  },
  logoBox: {
    flex: '0 0 auto',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center'
  }
});

function MyAppBar(props) {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Box flex='1'>
        <Box className={classes.logoBox}>
          <img src='logo.png' alt='logo' className={classes.logo} />
          <Typography variant='h6' color='inherit'>
            Surf Admin Panel
          </Typography>
        </Box>
      </Box>
      <Box flex='1'>
        <Typography variant='h6' color='inherit' id='react-admin-title' />
      </Box>
      <ThemeToggler />
    </AppBar>
  );
}
function CustomLayout(props) {
  return <Layout {...props} appBar={MyAppBar} />;
}
export default CustomLayout;

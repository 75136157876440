import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  Datagrid,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  DateField
} from 'react-admin';
import { Pagination } from '../components/Pagination';

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} pagination={<Pagination />}>
      <Datagrid>
        <TextField sortable={false} source='message' />
        <TextField sortable={false} source='action' />
        <DateField showTime sortable={false} source='created_at' />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <NotificationForm />
    </ReactAdminCreate>
  );
}

function NotificationForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput label='Message' fullWidth required source='message' />
      <TextInput fullWidth source='action' />
    </SimpleForm>
  );
}

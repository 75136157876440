import * as React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function TenantAutoCompleteInput({ source, validate }) {
  const [options, setOptions] = React.useState([]);

  const fetchOptions = () => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/tenants`)
      .then((resp) => resp.json)
      .then(async (response) => {
        setOptions(response);
      });
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div>
      <SelectInput
        fullWidth
        key={source}
        source={source}
        alwaysOn
        choices={options}
        validate={validate}
        helperText=''
      />
    </div>
  );
}

TenantAutoCompleteInput.propTypes = {
  source: PropTypes.string,
  validate: PropTypes.func
};

import * as React from 'react';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Queue from '@material-ui/icons/Queue';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useResourceContext } from 'ra-core';
import { Button } from 'react-admin';

const defaultIcon = <Queue />;
const stopPropagation = (e) => e.stopPropagation();
const omitId = ({ id, sk, ...rest }) => rest;
export function CloneButton({
  basePath = '',
  label = 'ra.action.clone',
  scrollToTop = true,
  record,
  icon = defaultIcon,
  ...rest
}) {
  const resource = useResourceContext();
  const pathname = basePath ? `${basePath}/create` : `/${resource}/create`;
  const newRecord = {
    pathname,
    search: queryString.stringify({
      source: JSON.stringify(omitId(record))
    }),
    state: { _scrollToTop: scrollToTop }
  };
  return (
    <Button
      component={Link}
      to={record ? newRecord : pathname}
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  );
}

CloneButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  scrollToTop: PropTypes.bool
};

export default memo(CloneButton);

module.exports.FEATURED_DISPLAY_ORDER = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' }
];

module.exports.ADMIN_AUDIT_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  GET: 'GET',
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT'
};

module.exports.MASS_USER_ACTIONS = [
  { id: 'DISABLE', name: 'Disable' },
  { id: 'ENABLE', name: 'Enable' },
  { id: 'DELETE', name: 'Delete' },
  { id: 'MARK_FRAUD', name: 'Mark as Fraud' },
  { id: 'UN_MARK_FRAUD', name: 'Un-Mark Fraud' }
];

// @see surf-api/utils/enumerable.js
module.exports.REWARD_TYPES_LIST = [
  { id: 'FAKE', name: 'Fake' },
  { id: 'GIFT_CARD', name: 'Gift Card' },
  { id: 'LIMITED_GIFT_CARD', name: 'Limited Gift Card' },
  { id: 'CHARITY', name: 'Charity' },
  { id: 'SUBSIDIZED_REWARD', name: 'Subsidized Reward' }
];

module.exports.REWARD_CATEGORIES = [
  { id: 'Items', name: 'Items' },
  { id: 'Gift Cards', name: 'Gift Cards' },
  { id: 'Coupon', name: 'Coupon' },
  { id: 'Digital Prizes', name: 'Digital Prizes' },
  { id: 'Cars', name: 'Cars' },
  { id: 'Charity', name: 'Charity' },
  { id: 'Item', name: 'Item' },
  { id: 'General', name: 'General' },
  { id: 'Sports', name: 'Sports' },
  { id: 'Gaming', name: 'Gaming' }
];

module.exports.REWARD_BRAND_TAGS = [
  { id: 'Automotive', name: 'Automotive' },
  { id: 'Beauty and Skincare', name: 'Beauty and Skincare' },
  { id: 'Clothing and Fashion', name: 'Clothing and Fashion' },
  { id: 'Gaming', name: 'Gaming' },
  { id: 'Electronics and Appliances', name: 'Electronics and Appliances' },
  { id: 'Entertainment', name: 'Entertainment' },
  { id: 'Groceries and Delivery', name: 'Groceries and Delivery' },
  { id: 'Health and Wellness', name: 'Health and Wellness' },
  { id: 'Home and Garden', name: 'Home and Garden' },
  { id: 'Online Shopping', name: 'Online Shopping' },
  { id: 'Pets', name: 'Pets' },
  { id: 'Restaurant', name: 'Restaurant' },
  { id: 'Retail', name: 'Retail' },
  { id: 'Sports and Outdoors', name: 'Sports and Outdoors' },
  { id: 'Travel', name: 'Travel' }
];

module.exports.REWARD_PROVIDER_OPTIONS = [
  { id: 'TANGO', name: 'Tango' },
  { id: 'BLACKHAWK', name: 'Blackhawk' }
];

module.exports.REDEMPTIONS_STATES = ['Unprocessed', 'Pending', 'Processed', 'Failed'];

module.exports.EXTENSION_STATES = ['NOT INSTALLED', 'ACTIVE', 'PAUSED', 'LOGGED OUT'];

// @see surf-web/components/forms/signup/GenderForm.js
module.exports.GENDER_OPTIONS = [
  { id: 'Male', name: 'Male' },
  { id: 'Female', name: 'Female' },
  { id: 'Non-binary', name: 'Non-binary' },
  { id: 'Other', name: 'Other' },
  { id: 'Rather not say', name: 'Rather not say' }
];

module.exports.TABLE_ROW_LIMIT = 25;
module.exports.TABLE_ROW_LOWER_LIMIT = 10;
module.exports.STAGE = process.env.REACT_APP_STAGE || 'staging';

module.exports.ADMIN_USER_ACTIONS = {
  ASSIGN_AFFILIATE_PROGRAM: 'ASSIGN_AFFILIATE_PROGRAM',
  CREATE_REFERRAL: 'CREATE_REFERRAL',
  APPLY_BONUS: 'APPLY_BONUS',
  CREATE_REDEMPTION: 'CREATE_REDEMPTION',
  MARK_FRAUD: 'MARK_FRAUD',
  UN_MARK_FRAUD: 'UN_MARK_FRAUD'
};

module.exports.AFFILIATE_NETWORKS = [
  { id: 'Impact', name: 'Impact' },
  { id: 'AWIN', name: 'Awin' },
  { id: 'ShareASale', name: 'ShareASale' },
  { id: 'PepperJam', name: 'PepperJam' }
];

module.exports.RA_PAGINATION_RESOURCES = ['rewards', 'brands', 'brandOffers'];

import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, TextInput, showNotification } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export const CreateReferralDialog = connect(null, { showNotification })(
  // eslint-disable-next-line no-shadow
  ({ show, setShow, referrer, showNotification, refreshPage }) => {
    const history = useHistory();
    const [multipleReferees, setMultipleReferees] = React.useState(false);
    const [refereeAccounts, setRefereeAccounts] = React.useState([]);
    const [selectedReferee, setSelectedReferee] = React.useState(null);
    const handleCloseClick = () => {
      setMultipleReferees(false);
      setRefereeAccounts([]);
      setShow(false);
    };

    const checkIfEmailExists = async (email) =>
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/users/email/${email}`)
        .then((resp) => resp.json)
        .catch(() => null);

    const processReferral = async (referee) => {
      fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/users/referral`, {
        method: 'POST',
        body: JSON.stringify({ referrer, referree: referee })
      })
        .then(() => {
          setShow(false);
          showNotification('Referral created successfully', 'success');
          refreshPage();
          history.push(`/users/${referrer}/show/referrals`);
          history.go();
        })
        .catch(() => ({ email: 'Error: Could not create referral.' }));
    };

    const submit = async (values) => {
      if (!selectedReferee) {
        if (!values.email) {
          return { email: 'The email is required' };
        }

        const referree = await checkIfEmailExists(values.email);
        if (!referree) {
          return { email: 'Referree account does not exist' };
        }

        if (referree.length > 1) {
          setRefereeAccounts(referree);
          setMultipleReferees(true);
        } else {
          processReferral(referree[0].sub);
        }
      } else {
        processReferral(selectedReferee);
      }
      return null;
    };

    return (
      <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label='Manual Referral'>
        <DialogTitle>Manual Referral</DialogTitle>
        <Form
          onSubmit={submit}
          render={(formProps) => (
            <>
              <DialogContent>
                <TextInput fullWidth label='Email' source='email' />
                {multipleReferees && (
                  <>
                    <div>
                      Multiple referees were found with same email. Please select one from dropdown.
                    </div>
                    <FormControl fullWidth variant='filled'>
                      <Select
                        onChange={(event) => setSelectedReferee(event.target.value)}
                        defaultValue=''
                      >
                        {refereeAccounts.map((i) => (
                          <MenuItem key={i.email} value={i.sub}>
                            {i.email} ({i.signup_flow} Signup)
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button label='Create' type='submit' onClick={formProps.handleSubmit} />
                <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    );
  }
);

import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  TextField,
  Datagrid,
  ShowButton,
  Edit as ReactAdminEdit,
  Create as ReactAdminCreate,
  List as ReactAdminList,
  NumberInput,
  NumberField,
  DateTimeInput,
  BooleanField
} from 'react-admin';
import { Pagination } from '../components/Pagination';

const bonusFilters = [<TextInput key='name' source='name' label='Bonus Name' alwaysOn />];

export function List(props) {
  return (
    <ReactAdminList {...props} perPage={25} filters={bonusFilters} pagination={<Pagination />}>
      <Datagrid>
        <TextField sortable={false} source='name' />
        <NumberField sortable={false} source='amount' />
        <NumberField sortable={false} source='limit' />
        <BooleanField sortable={false} source='notification' looseValue />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Create(props) {
  return (
    <ReactAdminCreate {...props}>
      <BonusForm />
    </ReactAdminCreate>
  );
}

export function Edit(props) {
  return (
    <ReactAdminEdit {...props}>
      <BonusForm />
    </ReactAdminEdit>
  );
}

function BonusForm(props) {
  return (
    <SimpleForm {...props}>
      <NumberInput required fullWidth source='amount' />
      <NumberInput required fullWidth source='limit' />
      <DateTimeInput fullWidth source='active_until' />
      <TextInput required fullWidth source='name' />
      <TextInput required fullWidth source='public_name' />
      <TextInput
        label='Notification template'
        fullWidth
        source='notification'
        helperText='Dynamic variables: {first_name}, {bonus_amount}'
      />
    </SimpleForm>
  );
}

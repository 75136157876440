import * as React from 'react';
import { Button } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function ProcessRedemptionDialog({ show, setShow, reloadData, redemptionId }) {
  const handleCloseClick = () => {
    setShow(false);
  };

  const updateProcessStatus = () => {
    fetchJson(
      `${process.env.REACT_APP_API_URL}${STAGE}/admin/redemptions/${redemptionId}/reprocess`,
      { method: 'POST' }
    ).then(() => {
      setShow(false);
      reloadData();
    });
  };

  return (
    <Dialog fullWidth open={show} onClose={handleCloseClick} aria-label='Process Redemption'>
      <DialogTitle>Process Redemption</DialogTitle>
      <DialogContent>
        Are you sure you want to reprocess the redemption for this user?
      </DialogContent>
      <DialogActions>
        <Button label='Update' onClick={updateProcessStatus} />
        <Button label='Cancel' onClick={handleCloseClick} color='secondary' />
      </DialogActions>
    </Dialog>
  );
}

ProcessRedemptionDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  reloadData: PropTypes.func,
  redemptionId: PropTypes.string
};

import React, { useState } from 'react';
import { DateField, BooleanField, FunctionField, Button } from 'react-admin';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { UrlShowField } from './UrlShowField';
import { UrlActionDialog } from './UrlActionDialog';

const useStyles = makeStyles({
  showMoreBtn: {
    width: '100%'
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: '600'
    }
  }
});

export function ReferralsTable({ referrals, userId, reloadReferrals }) {
  const classes = useStyles();
  const [referralId, setReferralId] = useState('');
  const [showCompleteReferralDialog, setShowCompleteReferralDialog] = useState(false);
  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Referred At</TableCell>
              <TableCell>User Email</TableCell>
              <TableCell>Completed</TableCell>
              <TableCell>Completed At</TableCell>
              <TableCell>Completed By Admin</TableCell>
              <TableCell>Created By Admin</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals && referrals.length > 0 ? (
              referrals.map((row) => (
                <TableRow hover key={row.created_at}>
                  <TableCell>
                    <DateField record={row} showTime source='created_at' />
                  </TableCell>
                  <TableCell>
                    <UrlShowField
                      record={row}
                      source='email'
                      target='_blank' // New window
                      rel='noopener noreferrer' // For security
                      resourceName='users'
                      urlSource='userId'
                    />
                  </TableCell>
                  <TableCell>
                    <BooleanField record={row} source='completed' />
                  </TableCell>
                  <TableCell>
                    <FunctionField
                      record={row}
                      render={(record) =>
                        record.completed && <DateField record={row} showTime source='updated_at' />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FunctionField
                      record={row}
                      render={(record) =>
                        record.completed && <BooleanField record={row} source='completedByAdmin' />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <BooleanField record={row} source='createdByAdmin' />
                  </TableCell>
                  <TableCell>
                    <FunctionField
                      record={row}
                      render={(record) =>
                        !record.completed && (
                          <Button
                            onClick={() => {
                              setReferralId(record.id);
                              setShowCompleteReferralDialog(true);
                            }}
                            label='Complete Referral'
                          />
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={7}>
                  No referrals created by user
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <UrlActionDialog
        url={`users/${referralId}/processReferral/${userId}`}
        show={showCompleteReferralDialog}
        btnClass={classes.enableBtn}
        setShow={setShowCompleteReferralDialog}
        title='Complete Referral'
        description='Are you sure you want to mark this referral as completed'
        method='GET'
        buttonLabel='Confirm'
        refreshData={reloadReferrals}
      />
    </>
  );
}

ReferralsTable.propTypes = {
  referrals: PropTypes.array,
  userId: PropTypes.string,
  reloadReferrals: PropTypes.func
};

import * as React from 'react';
import {
  TextField,
  FunctionField,
  List as ReactAdminList,
  Datagrid,
  Pagination,
  TextInput
} from 'react-admin';
import { Link } from '@material-ui/core';
import { UrlShowField } from '../components/UrlShowField';

const redemptionsFilters = [
  <TextInput key='email' source='email' label='User Email' alwaysOn />,
  <TextInput key='giveaway_name' source='giveaway_name' label='Giveaway Name' alwaysOn />
];

export function List(props) {
  return (
    <ReactAdminList
      {...props}
      perPage={25}
      pagination={<Pagination />}
      filters={redemptionsFilters}
    >
      <Datagrid>
        <FunctionField
          label='Redeemed At'
          render={(record) => new Date(+record.time_token).toLocaleString()}
        />
        <UrlShowField
          source='user_email'
          target='_blank'
          rel='noopener noreferrer'
          resourceName='users'
          urlSource='user_id'
        />
        <FunctionField
          label='Redeemed At'
          render={(record) => (
            <Link href={record.giveaway_name} target='_blank'>
              {record.reward_name}
            </Link>
          )}
        />
        <TextField label='Bot Score' source='user_bot_score' />
        <TextField label='Bot Score Reason' source='user_bot_score_reason' />
      </Datagrid>
    </ReactAdminList>
  );
}

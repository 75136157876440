import * as React from 'react';
import { AutocompleteArrayInput, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';
import { fetchJson } from '../dataProvider';
import { STAGE } from '../utils/constants';

export function RegionAutoCompleteInput({ source, validate, showSelect, showAll, ...props }) {
  const [options, setOptions] = React.useState([]);

  const fetchOptions = () => {
    fetchJson(`${process.env.REACT_APP_API_URL}${STAGE}/admin/regions`)
      .then((resp) => resp.json)
      .then(async (response) => {
        setOptions(showAll ? [{ id: 'all', name: 'All' }].concat(response) : response);
      });
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div>
      {showSelect ? (
        <SelectInput
          alwaysOn
          allowEmpty={false}
          key={source}
          source={source}
          choices={options}
          helperText=''
          {...props}
        />
      ) : (
        <AutocompleteArrayInput
          fullWidth
          key={source}
          source={source}
          alwaysOn
          choices={options}
          validate={validate}
          helperText=''
          {...props}
        />
      )}
    </div>
  );
}

RegionAutoCompleteInput.propTypes = {
  source: PropTypes.string,
  validate: PropTypes.func,
  showSelect: PropTypes.bool,
  showAll: PropTypes.bool
};

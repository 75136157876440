import * as React from 'react';
import {
  SimpleForm,
  TextField,
  Show as ReactAdminShow,
  TopToolbar,
  Button,
  FunctionField,
  DateField,
  List as ReactAdminList,
  Datagrid,
  Pagination,
  ShowButton,
  TextInput,
  SelectInput
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import IconEvent from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { REWARDS_REDEMPTION_STATUS } from 'surf-api/utils/enumerable';
import { fetchJson } from '../dataProvider';
import { REDEMPTIONS_STATES, STAGE } from '../utils/constants';
import { UrlShowField } from '../components/UrlShowField';
import { TenantAutoCompleteInput } from '../components/TenantAutoCompleteInput';

const useStyles = makeStyles({
  failedState: {
    background: 'red',
    color: 'white',
    height: '27px'
  },
  unprocessedState: {
    background: 'yellow',
    color: 'black',
    height: '27px'
  },
  processedState: {
    background: 'green',
    color: 'white',
    height: '27px'
  },
  pendingState: {
    background: 'grey',
    color: 'white',
    height: '27px'
  }
});

const redemptionsFilters = [
  <TenantAutoCompleteInput source='tenant_id' label='Tenant' alwaysOn />,
  <TextInput key='email' source='email' label='User Email' alwaysOn />,
  <TextInput key='reward_name' source='reward_name' label='Reward Name' alwaysOn />,
  <SelectInput
    key='state'
    source='state'
    label='Status'
    alwaysOn
    allowEmpty={false}
    choices={[
      { id: null, name: 'All' },
      { id: 0, name: REDEMPTIONS_STATES[0] },
      { id: 1, name: REDEMPTIONS_STATES[1] },
      { id: 2, name: REDEMPTIONS_STATES[2] },
      { id: 3, name: REDEMPTIONS_STATES[3] }
    ]}
  />
];

function RedemptionActions({ data = {} }) {
  const [canReprocess, setCanReprocess] = React.useState(true);
  const [canResend, setCanResend] = React.useState(true);

  const onReprocess = () => {
    setCanReprocess(false);
    fetchJson(
      `${process.env.REACT_APP_API_URL}${STAGE}/admin/tenantRedemptions/${data.id}/reprocess`,
      {
        method: 'POST'
      }
    ).then(() => window.location.reload());
  };

  const onResend = () => {
    setCanResend(false);
    fetchJson(
      `${process.env.REACT_APP_API_URL}${STAGE}/admin/tenantRedemptions/${data.id}/resend`,
      {
        method: 'POST'
        // eslint-disable-next-line no-alert
      }
      // eslint-disable-next-line no-alert
    ).catch(() => window.alert('Failed to resend redemption email'));
  };

  return (
    <TopToolbar>
      {(data?.state === 0 || data?.state === 3) && (
        <Button disabled={!canReprocess} onClick={onReprocess} label='Process redemption'>
          <IconEvent />
        </Button>
      )}
      {(data?.state === 2 || data?.provider_order_id || !data?.email_sent) && (
        <Button disabled={!canResend} onClick={onResend} label='Resend redemption email'>
          <IconEvent />
        </Button>
      )}
    </TopToolbar>
  );
}

RedemptionActions.propTypes = {
  data: PropTypes.object
};

export function List(props) {
  const classes = useStyles();

  return (
    <ReactAdminList
      {...props}
      perPage={25}
      pagination={<Pagination />}
      filters={redemptionsFilters}
    >
      <Datagrid>
        <FunctionField
          label='Redeemed At'
          render={(record) => new Date(+record.time_token).toLocaleString()}
        />
        <TextField source='user_email' />
        <FunctionField
          addLabel
          label='Reward name'
          render={(record) => (
            <>
              <UrlShowField
                source='reward_name'
                target='_blank'
                rel='noopener noreferrer'
                resourceName='rewards'
                urlSource='reward_id'
              />
              {record.discount_code && (
                <strong>
                  &nbsp;
                  <br />({record.discount_code})
                </strong>
              )}
            </>
          )}
        />
        <TextField label='Reward Type' source='reward_type' />
        <FunctionField
          addLabel
          label='Status'
          render={(record) => (
            <Chip
              className={classes[`${REDEMPTIONS_STATES[record.state || 0].toLowerCase()}State`]}
              color='secondary'
              label={REDEMPTIONS_STATES[record.state || 0]}
            />
          )}
        />
        <ShowButton />
      </Datagrid>
    </ReactAdminList>
  );
}

export function Show(props) {
  return (
    <ReactAdminShow {...props} actions={<RedemptionActions />}>
      <RedemptionForm isEdit />
    </ReactAdminShow>
  );
}

function RedemptionForm({ isEdit, ...props }) {
  return (
    <SimpleForm {...props}>
      <DateField label='Redeemed at' showTime source='created_at' />
      <TextField label='User Email' source='user_email' />
      <UrlShowField
        label='Reward'
        source='reward_name'
        target='_blank'
        rel='noopener noreferrer'
        resourceName='rewards'
        urlSource='reward_id'
      />
      <TextField label='Reward Type' source='reward_type' />
      <FunctionField label='State' render={(record) => REDEMPTIONS_STATES[record.state || 0]} />
      <FunctionField
        label='Failure Reason'
        render={(record) =>
          record.state === REWARDS_REDEMPTION_STATUS.FAILED ||
          record.state === REWARDS_REDEMPTION_STATUS.UNPROCESSED
            ? record.failure_reason
            : 'N/A'
        }
      />
      <TextField source='value' />
      <TextField source='provider_reward_id' />
      <TextField source='provider_order_id' />
    </SimpleForm>
  );
}

RedemptionForm.propTypes = {
  isEdit: PropTypes.bool
};
